import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import 'glightbox/dist/css/glightbox.css'; // Ensure you have GLightbox library installed and imported
import GLightbox from 'glightbox';

const CallToAction = () => {
  useEffect(() => {
    AOS.init();
    GLightbox({ selector: '.glightbox' });
  }, []);

  return (
    <section id="call-to-action" className="call-to-action section">
      <div className="container">
        <img src={`${process.env.PUBLIC_URL}/newimages/machin.jpg`} alt="" />
        <div className="content row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
          <div className="col-xl-10">
            <div className="text-center">
              <a href={`${process.env.PUBLIC_URL}/newimages/machin.jpg`} className="glightbox play-btn"></a>
              <h3>Call To Action</h3>
              <p>FORKLIFT TRAININGS.</p>
              <a className="cta-btn" href="#">Call To Action</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
