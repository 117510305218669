import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-about">
            <a href="index.html" className="logo d-flex align-items-center">
              <span className="sitename">Hose Consult Uganda</span>
            </a>
            <p>Hose Consults Uganda Ltd is responsible for setting up good relationships with our clients and Students.
               We ensure that the best services and training materials are offered to our clients and students so that
                they keep in a competitive world in terms of compliance to legal Acts, Regulations, Laws, and ISO standards.
              Our employees are very responsible for every action they take, and human resource capital is vital for
               sustainable development.
              </p>
            <div className="social-links d-flex mt-4">
              <a href=""><i className="bi bi-twitter-x"></i></a>
              <a href=""><i className="bi bi-facebook"></i></a>
              <a href=""><i className="bi bi-instagram"></i></a>
              <a href=""><i className="bi bi-linkedin"></i></a>
            </div>
          </div>
          <div className="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">About us</a></li>
              <li><a href="#">Services</a></li>
              <li><a href="#">Terms of service</a></li>
              <li><a href="#">Privacy policy</a></li>
            </ul>
          </div>
          <div className="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><a href="#">Fire Safety Prevention and Fighting Training</a></li>
              <li><a href="#">First Aid Training Course at workplace</a></li>
              <li><a href="#">Electrical Safety and IP Rating Training</a></li>
              <li><a href="#">Permit to Work (PTW) systems Training</a></li>
              <li><a href="#">Construction Safety and Management Training </a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p>P.O BOX 7111</p>
            <p> KAMPALA </p>
            <p>(U)/Jinja-Rubaga</p>
            <p className="mt-4"><strong>Phone:</strong> <span>+256 782 8562 77</span></p>
            <p><strong>Email:</strong> <span>info@hoseconsultsugandltd.com</span></p>
          </div>
        </div>
      </div>
      <div className="container copyright text-center mt-4">
        <p>© <span>Copyright</span> <strong className="px-1 sitename">hoseconsultsugandltd</strong> <span>All Rights Reserved</span></p>
        <div className="credits">
          {/* All the links in the footer should remain intact. */}
          {/* You can delete the links only if you've purchased the pro version. */}
          {/* Licensing information: https://bootstrapmade.com/license/ */}
          {/* Purchase the pro version with working PHP/AJAX contact form: [buy-url] */}
          Designed by CoDzInIfomation Technology<a href="#"></a>
        </div>
      </div>
      
    </footer>
  );
}

export default Footer;
