import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure you have Bootstrap Icons installed and imported

const Testimonials = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
          
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section id="testimonials" className="testimonials section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Testimonials</h2>
        <p>Our clients/customers are the major reasons we exist and therefore we aim at giving them the best services.</p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <Slider {...settings}>
          <div className="testimonial-item">
            <img src={`${process.env.PUBLIC_URL}/img/clients/client-1.png`} className="testimonial-img" alt="Client 1" />
            <h3>Wagagai Limited.</h3>
            <div className="stars">
              <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
            </div>
            <p>
              <i className="bi bi-quote quote-icon-left"></i>
              <span>Working with Hoseconsult has significantly enhanced our workplace safety protocols. Their comprehensive training sessions were insightful and tailored to our specific needs. We are now more confident in managing health and safety risks.</span>
              <i className="bi bi-quote quote-icon-right"></i>
            </p>
          </div>

          <div className="testimonial-item">
            <img src={`${process.env.PUBLIC_URL}/img/clients/kyoga-1.png`} className="testimonial-img" alt="Client 2" />
            <h3>Kyoga Dynamics Uganda Ltd</h3>
            <div className="stars">
              <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
            </div>
            <p>
              <i className="bi bi-quote quote-icon-left"></i>
              <span>The consultants from Hoseconsult were highly professional and knowledgeable. Their hands-on approach and practical advice have been invaluable in improving our safety standards. Highly recommended.</span>
              <i className="bi bi-quote quote-icon-right"></i>
            </p>
          </div>

          <div className="testimonial-item">
            <img  src={`${process.env.PUBLIC_URL}/img/clients/client-7.jpg`} className="testimonial-img" alt="Client 3" />
            <h3>Uber Technologies Uganda LTD</h3>
            <div className="stars">
              <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
            </div>
            <p>
              <i className="bi bi-quote quote-icon-left"></i>
              <span>We were impressed by the depth of expertise demonstrated by Hoseconsult. The training was engaging and effective, making a noticeable difference in our team's safety awareness and practices.</span>
              <i className="bi bi-quote quote-icon-right"></i>
            </p>
          </div>

          <div className="testimonial-item">
            <img  src={`${process.env.PUBLIC_URL}/img/clients/cleant-10.svg`} className="testimonial-img" alt="Client 4" />
            <h3>SINO-HYDRO (U) Ltd</h3>
            <div className="stars">
              <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
            </div>
            <p>
              <i className="bi bi-quote quote-icon-left"></i>
              <span>Hoseconsult provided exceptional service and support throughout the training process. Their customized solutions were exactly what we needed to address our unique safety challenges. A great partnership!</span>
              <i className="bi bi-quote quote-icon-right"></i>
            </p>
          </div>

          <div className="testimonial-item">
            <img  src={`${process.env.PUBLIC_URL}/img/clients/client-3.jpg`} className="testimonial-img" alt="Client 5" />
            <h3>M-Kopa Solar Uganda.</h3>
            <div className="stars">
              <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
            </div>
            <p>
              <i className="bi bi-quote quote-icon-left"></i>
              <span>From start to finish, Hoseconsult delivered outstanding health and safety training. Their consultants were responsive, thorough, and genuinely invested in our success. We couldn't be happier with the results.</span>
              <i className="bi bi-quote quote-icon-right"></i>
            </p>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
