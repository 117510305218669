
import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import Heroall from './heroall';


const Team = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <Heroall/>
    <section  className="team section">
      {/* Section Title */}
      

      <div className="container">
        <div className="row gy-4">
          {/* Team Member 1 */}
          <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
            <div className="member">
              <img src={`${process.env.PUBLIC_URL}/img/team/team-1.jpg`} className="img-fluid" alt="" />
              <h4>Wamboga Godfrey</h4>
              <span>Managing Director</span>
              <div className="social">
                <a href=""><i className="bi bi-twitter-x"></i></a>
                <a href=""><i className="bi bi-facebook"></i></a>
                <a href=""><i className="bi bi-instagram"></i></a>
                <a href=""><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>{/* End Team Member */}
          <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
            <div class="member">
              <img src={`${process.env.PUBLIC_URL}/img/team/team-2.jpg`} class="img-fluid" alt=""/>
              <h4>Masesa Hellen</h4>
              <span>HR. Consultant & Client Manager</span>
              <div class="social">
                <a href=""><i class="bi bi-twitter-x"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
            <div class="member">
              <img src={`${process.env.PUBLIC_URL}/img/team/team-3.jpg`} class="img-fluid" alt=""/>
              <h4>Mary Ahairwe</h4>
              <span>Director-Administration and Human Resources</span>
              <div class="social">
                <a href=""><i class="bi bi-twitter-x"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
            <div class="member">
              <img src={`${process.env.PUBLIC_URL}/img/team/team-3.jpg`} class="img-fluid" alt=""/>
              <h4>Mary Ahairwe</h4>
              <span>Content</span>
              <div class="social">
                <a href=""><i class="bi bi-twitter-x"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
            <div class="member">
              <img src={`${process.env.PUBLIC_URL}/img/team/team-4.jpg`} class="img-fluid" alt=""/>
              <h4>Esta Hamba</h4>
              <span>Accountant and Finance Manager</span>
              <div class="social">
                <a href=""><i class="bi bi-twitter-x"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
          {/* Other Team Members */}
        </div>
      </div>
    </section>
    </>
  );
};

export default Team;
