import React, { useEffect, useState } from 'react';

const YourComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      {/* Your other JSX content goes here */}
      
      {/* Scroll Top */}
      {isVisible && (
        <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center" onClick={scrollToTop}>
          <i className="bi bi-arrow-up-short"></i>
        </a>
      )}
    </div>
  );
}

export default YourComponent;
