import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';

const pageContent = {
  "/couses": {
    title: "Courses",
    description: "Explore our wide range of courses designed to help you gain new skills and advance your career. Our courses are taught by experienced instructors and cover a variety of subjects to meet your educational needs."
  },
  "/about": {
    title: "About Us",
    description: "We are committed to providing the best service. Our team is dedicated to your success and satisfaction."
  },
  "/contact": {
    title: "Contact Us",
    description: "Feel free to reach out to us with any inquiries. We're here to help you with whatever you need."
  },
  "/services": {
    title: "Our Services",
    description: "Hose Consults Uganda Ltd offers other services below."
  },
  "/team": {
    title: "Our Team",
    description: "We are honest and truthful in all our actions at Hose Consults Uganda Limited."
  },
  "/blog": {
    title: "Recent Blog Posts",
    description: "OSH   ENVIRONMENT  QUALITY SECURITY    HR CONSULTANCY"
  },
  "/course-details/1": {
    title: "Course Details",
    description: "test 1"
  },
  "/course-details/2": {
    title: "Course Details",
    description: "OSH   ENVIRONMENT  QUALITY SECURITY    HR CONSULTANCY COURSES"
  },
  "/course-details/3": {
    title: "Course Details",
    description: "test 3"
  },
  // Add more paths as needed
};

function Heroallcourse() {
  useEffect(() => {
    AOS.init();
  }, []);

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);
  const currentPath = `/${pathnames.join('/')}`;
  const {  } = pageContent[currentPath] || { title: "Page Not Found", description: "The page you are looking for does not exist." };

  return (
    <div className="page-title" data-aos="fade">
      <div className="heading">
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            
          </div>
        </div>
      </div>
      <nav className="breadcrumbs">
        <div className="container">
          <ol>
            <li><Link to="/">Home</Link></li>
            {pathnames.map((value, index) => {
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;
              const isLast = index === pathnames.length - 1;
              return isLast ? (
                <li key={to} className="current">{value}</li>
              ) : (
                <li key={to}><Link to={to}>{value}</Link></li>
              );
            })}
          </ol>
        </div>
      </nav>
    </div>
  );
}

export default Heroallcourse;
