
import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import Heroall from './heroall';
import Client from './client';
import Service from './service';
import Hero from './Hero';

const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    
    <Heroall/>
    <Service/>
     <section id="about" className="about">
    
      <div class="container section-title" data-aos="fade-up">
        <h2>Who Are We<br/></h2>
        <p>We are honest and truthful in all our actions at Hose Consults Uganda Limited. </p>
      </div>
      <div class="container">

        <div class="row gy-4">
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3>Hose Consults Uganda Ltd was formed in 2013 with an aim of tackling the following </h3>
            <img src={`${process.env.PUBLIC_URL}/newimages/train.jpg`} class="img-fluid rounded-4 mb-4" alt=""/>
            <img src={`${process.env.PUBLIC_URL}/newimages/second.jpg`} class="img-fluid rounded-4 mb-4" alt=""/>
            <p>There is an increased lack of knowledge and practical skills in the field of occupational health and safety which results into loss of life and property due to incidents and accidents  
              that occur in different organizations.   
              </p>
            <p>Hose Consults Uganda Ltd was formed to address this through offering safety 
              trainings to curb down organizational incidents and accidents in our society today.</p>
          </div>
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <div class="content ps-0 ps-lg-5">
              <p class="fst-italic">
                Hose Consults Uganda Ltd stands to address the issues of office safety through ergonomic trainings. 
              </p>
              <ul>
                <li><i class="bi bi-check-circle-fill"></i> <span>Compliance and legal implications.</span></li>
                <li><i class="bi bi-check-circle-fill"></i> <span>Human resource services.</span></li>
                <li><i class="bi bi-check-circle-fill"></i> <span>Entrepreneurship skills and training.</span></li>
                <li><i class="bi bi-check-circle-fill"></i> <span>Leadership and capacity building training.</span></li>
                <li><i class="bi bi-check-circle-fill"></i> <span>General Occupational health and safety and environmental consultancy.</span></li>
              </ul>
              <p>
                We shall lead in consultancy and Trainings on Occupational Health, Safety and Environment, 
                Security, Quality and Human resource services in Uganda and East Africa.
              </p>

              <div class="position-relative mt-4">
                <img src={`${process.env.PUBLIC_URL}/newimages/videoimg.jpg`} class="img-fluid rounded-4" alt=""/>
                <a href="https://youtu.be/W--iI196xjA?si=P56yqmzz9OdTxbxo" class="glightbox play-btn"></a>
              </div>
            </div>
          </div>
        </div>

      </div>
    
    </section>
    </>
  );
};

export default About;
