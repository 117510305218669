
import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import Heroall from './heroall';

const Blog = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <Heroall/>
    <section id="blog" className="recent-posts section">
      <div className="container">
        
        <div className="row gy-4">
          <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <article>
              <div className="post-img">
                <img  src={`${process.env.PUBLIC_URL}/img/portfolio/branding-1.jpg`} alt="" className="img-fluid" />
              </div>
              <p className="post-category">Training</p>
              <h2 className="title">
                <a href="blog-details.html">HR recruitment services/Consultancy</a>
              </h2>
              <div className="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/img/team/team-1.jpg`} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author">Mr Wamboga</p>
                  <p className="post-date">
                    <time dateTime="2022-01-01">Jan 1, 2022</time>
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <article>
              <div className="post-img">
                <img  src={`${process.env.PUBLIC_URL}/img/blog/blog-7.jpg`} alt="" className="img-fluid" />
              </div>
              <p className="post-category">Inspection</p>
              <h2 className="title">
                <a href="blog-details.html">MACHINE INSPECTIONS AND TRAININGS</a>
              </h2>
              <div className="d-flex align-items-center">
                <img  src={`${process.env.PUBLIC_URL}/img/team/team-2.jpg`} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author">Mesesa Hellen</p>
                  <p className="post-date">
                    <time dateTime="2022-01-01">Jun 5, 2023</time>
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <article>

              <div class="post-img">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/App-1.jpg`} alt="" class="img-fluid"/>
              </div>

              <p class="post-category">Analysis</p>

              <h2 class="title">
                <a href="blog-details.html">Water Quality Analysis and Wastewater quality analysis.</a>
              </h2>

              <div class="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/img/team/team-1.jpg`} alt="" class="img-fluid post-author-img flex-shrink-0"/>
                <div class="post-meta">
                  <p class="post-author">Godfrey Wamboga</p>
                  <p class="post-date">
                    <time datetime="2022-01-01">Jun 9, 2023</time>
                  </p>
                </div>
              </div>

            </article>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <article>

              <div class="post-img">
                <img src={`${process.env.PUBLIC_URL}/img/blog/blog-1.jpg`} alt="" class="img-fluid"/>
              </div>

              <p class="post-category">Counseling</p>

              <h2 class="title">
                <a href="blog-details.html">HIV/Aids Training and Counseling services</a>
              </h2>

              <div class="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/img/team/team-3.jpg`} alt="" class="img-fluid post-author-img flex-shrink-0"/>
                <div class="post-meta">
                  <p class="post-author">Mary Ahairwe</p>
                  <p class="post-date">
                    <time datetime="2022-01-01">Jan 4, 2019</time>
                  </p>
                </div>
              </div>

            </article>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <article>

              <div class="post-img">
                <img src={`${process.env.PUBLIC_URL}/img/blog/blog-2.jpg`} alt="" class="img-fluid"/>
              </div>

              <p class="post-category">HEALTHY</p>

              <h2 class="title">
                <a href="blog-details.html">First Aid Training Course at workplace</a>
              </h2>

              <div class="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/img/team/team-4.jpg`} alt="" class="img-fluid post-author-img flex-shrink-0"/>
                <div class="post-meta">
                  <p class="post-author">Esta Hamba</p>
                  <p class="post-date">
                    <time datetime="2022-01-01">July 9, 2023</time>
                  </p>
                </div>
              </div>

            </article>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <article>

              <div class="post-img">
                <img src={`${process.env.PUBLIC_URL}/img/blog/blog-3.jpg`} alt="" class="img-fluid"/>
              </div>

              <p class="post-category">Training</p>

              <h2 class="title">
                <a href="blog-details.html">Fire Safety Prevention and Fighting Training</a>
              </h2>

              <div class="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/img/team/team-1.jpg`} alt="" class="img-fluid post-author-img flex-shrink-0"/>
                <div class="post-meta">
                  <p class="post-author">Godfrey Wamboga</p>
                  <p class="post-date">
                    <time datetime="2022-01-01">Jun 5, 2021</time>
                  </p>
                </div>
              </div>

            </article>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <article>

              <div class="post-img">
                <img src={`${process.env.PUBLIC_URL}/img/blog/blog-4.jpg`} alt="" class="img-fluid"/>
              </div>

              <p class="post-category">Consultancy</p>

              <h2 class="title">
                <a href="blog-details.html">Management of Contractors & Visitors Training </a>
              </h2>

              <div class="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/img/team/team-3.jpg`} alt="" class="img-fluid post-author-img flex-shrink-0"/>
                <div class="post-meta">
                  <p class="post-author">Mary Hairwe</p>
                  <p class="post-date">
                    <time datetime="2022-01-01">Jun 1, 2021</time>
                  </p>
                </div>
              </div>

            </article>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <article>

              <div class="post-img">
                <img src={`${process.env.PUBLIC_URL}/img/blog/blog-5.jpg`} alt="" class="img-fluid"/>
              </div>

              <p class="post-category">QUALITY SERVICES</p>

              <h2 class="title">
                <a href="blog-details.html">Food safety and HACCP Training </a>
              </h2>

              <div class="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/img/team/team-1.jpg`} alt="" class="img-fluid post-author-img flex-shrink-0"/>
                <div class="post-meta">
                  <p class="post-author">Godfrey Wamboga</p>
                  <p class="post-date">
                    <time datetime="2022-01-01">Jun 11, 2023</time>
                  </p>
                </div>
              </div>

            </article>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <article>

              <div class="post-img">
                <img src={`${process.env.PUBLIC_URL}/img/blog/blog-6.jpg`} alt="" class="img-fluid"/>
              </div>

              <p class="post-category">Training</p>

              <h2 class="title">
                <a href="blog-details.html">HR recruitment services/Consultancy</a>
              </h2>

              <div class="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/img/team/team-2.jpg`} alt="" class="img-fluid post-author-img flex-shrink-0"/>
                <div class="post-meta">
                  <p class="post-author">Mesesa Hellen</p>
                  <p class="post-date">
                    <time datetime="2022-01-01">Jun 19, 2023</time>
                  </p>
                </div>
              </div>

            </article>
          </div>

          {/* Add more similar blocks for other blog posts */}

        </div>
      </div>
    </section>
    </>
  );
};

export default Blog;
