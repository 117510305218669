import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar, Button, Card, Col, Row, Container } from 'react-bootstrap';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Heroallcourse from '../Herocourse';
import { Link, useNavigate } from 'react-router-dom';
import Courses from '../couses';
import axios from 'axios';

const CourseProgress = () => {
  const totalLessons = 88; // Adjust this based on your total number of lessons
  const [progress, setProgress] = useState(0);
  const [progresss, setProgresss] = useState(0);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [currentTrackEnded, setCurrentTrackEnded] = useState(false);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    AOS.init();
    fetchProgress();
  }, []);

  const fetchProgress = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      const response = await axios.get('https://djangomyaip.hoseconsultsugandaltd.com/progress/', {
        headers: {
          Authorization: `Token ${user.token}`
        }
      });
       // Log the entire response data
  
      const progressData = response.data;
      setProgresss(progressData.progress);
      
      if (progressData.length > 0) {
        console.log(progressData.progress)
        const { current_lesson, progress } = progressData[0];
        
        setCurrentLessonIndex(current_lesson);
        
      }
    } catch (error) {
      console.error('Error fetching progress:', error);
    }
  };
  console.log(progresss)
 
  const saveProgress = async (lessonIndex, progress) => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      await axios.post('https://djangomyaip.hoseconsultsugandaltd.com/progress/', {
        course_id: 1, // Adjust based on your course identification logic
        current_lesson: lessonIndex,
        progress: progress,
      }, {
        headers: {
          Authorization: `Token ${user.token}`
        }
      });
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  };

  const handleNextLesson = async () => {
    if (currentLessonIndex < totalLessons - 1) {
      const newLessonIndex = currentLessonIndex + 1;
      const newProgress = ((newLessonIndex + 1) / totalLessons) * 100;
      setCurrentLessonIndex(newLessonIndex);
      setProgress(newProgress);
      setCurrentTrackEnded(false); // Reset for the next lesson
      setIsPlaying(false); // Reset playing state for the next lesson
      setCurrentTime(0); // Reset progress bar for the next lesson

      await saveProgress(newLessonIndex, newProgress); // Save progress here
    }
  };

  const handlePreviousLesson = () => {
    if (currentLessonIndex > 0) {
      const newLessonIndex = currentLessonIndex - 1;
      setCurrentLessonIndex(newLessonIndex);
      setProgress(((newLessonIndex + 1) / totalLessons) * 100);
      setIsPlaying(false); // Reset playing state for the previous lesson
      setCurrentTime(0); // Reset progress bar for the previous lesson
    }
  };

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    setCurrentTime(audio.currentTime);
    setDuration(audio.duration);
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('ended', () => {
        setCurrentTrackEnded(true);
      });
      audio.addEventListener('timeupdate', handleTimeUpdate);
    }
    return () => {
      if (audio) {
        audio.removeEventListener('ended', () => {
          setCurrentTrackEnded(true);
        });
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [currentLessonIndex]);

  const audioTracks = [
    {
      title: 'Lesson 01',
      src: `${process.env.PUBLIC_URL}/audio/slide_1.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/1.png`
    },
    {
      title: 'Lesson 02',
      src: `${process.env.PUBLIC_URL}/audio/slide_2.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/2.png`
    },
    {
      title: 'Lesson 03',
      src: `${process.env.PUBLIC_URL}/audio/slide_3.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/3.png`
    },
    {
      title: 'Lesson 04',
      src: `${process.env.PUBLIC_URL}/audio/slide_4.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/4.png`
    },
    {
      title: 'Lesson 05',
      src: `${process.env.PUBLIC_URL}/audio/slide_5.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/5.png`
    },
    {
      title: 'Lesson 06',
      src: `${process.env.PUBLIC_URL}/audio/slide_6.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/6.png`
    },
    {
      title: 'Lesson 07',
      src: `${process.env.PUBLIC_URL}/audio/slide_7.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/7.png`
    },
    {
      title: 'Lesson 08',
      src: `${process.env.PUBLIC_URL}/audio/slide_8.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/8.png`
    },
    {
      title: 'Lesson 09',
      src: `${process.env.PUBLIC_URL}/audio/slide_9.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/9.png`
    },
    {
      title: 'Lesson 10',
      src: `${process.env.PUBLIC_URL}/audio/slide_10.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/10.png`
    },
    {
      title: 'Lesson 11',
      src: `${process.env.PUBLIC_URL}/audio/slide_11.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/11.png`
    },
    {
      title: 'Lesson 12',
      src: `${process.env.PUBLIC_URL}/audio/slide_12.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/12.png`
    },
    {
      title: 'Lesson 13',
      src: `${process.env.PUBLIC_URL}/audio/slide_13.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/13.png`
    },
    {
      title: 'Lesson 14',
      src: `${process.env.PUBLIC_URL}/audio/slide_14.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/14.png`
    },
    {
      title: 'Lesson 15',
      src: `${process.env.PUBLIC_URL}/audio/slide_15.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/15.png`
    },
    {
      title: 'Lesson 16',
      src: `${process.env.PUBLIC_URL}/audio/slide_16.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/16.png`
    },
    {
      title: 'Lesson 17',
      src: `${process.env.PUBLIC_URL}/audio/slide_17.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/17.png`
    },
    {
      title: 'Lesson 18',
      src: `${process.env.PUBLIC_URL}/audio/slide_18.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/18.png`
    },
    {
      title: 'Lesson 19',
      src: `${process.env.PUBLIC_URL}/audio/slide_19.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/19.png`
    },
    {
      title: 'Lesson 20',
      src: `${process.env.PUBLIC_URL}/audio/slide_20.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/20.png`
    },
    {
      title: 'Lesson 21',
      src: `${process.env.PUBLIC_URL}/audio/slide_21.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/21.png`
    },
    {
      title: 'Lesson 22',
      src: `${process.env.PUBLIC_URL}/audio/slide_22.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/22.png`
    },
    {
      title: 'Lesson 23',
      src: `${process.env.PUBLIC_URL}/audio/slide_23.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/23.png`
    },
    {
      title: 'Lesson 24',
      src: `${process.env.PUBLIC_URL}/audio/slide_24.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/24.png`
    },
    {
      title: 'Lesson 25',
      src: `${process.env.PUBLIC_URL}/audio/slide_25.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/25.png`
    },
    {
      title: 'Lesson 26',
      src: `${process.env.PUBLIC_URL}/audio/slide_26.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/26.png`
    },
    {
      title: 'Lesson 27',
      src: `${process.env.PUBLIC_URL}/audio/slide_27.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/27.png`
    },
    {
      title: 'Lesson 28',
      src: `${process.env.PUBLIC_URL}/audio/slide_28.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/28.png`
    },
    {
      title: 'Lesson 29',
      src: `${process.env.PUBLIC_URL}/audio/slide_29.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/29.png`
    },
    {
      title: 'Lesson 30',
      src: `${process.env.PUBLIC_URL}/audio/slide_30.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/30.png`
    },
    {
      title: 'Lesson 31',
      src: `${process.env.PUBLIC_URL}/audio/slide_31.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/31.png`
    },
    {
      title: 'Lesson 32',
      src: `${process.env.PUBLIC_URL}/audio/slide_32.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/32.png`
    },
    {
      title: 'Lesson 33',
      src: `${process.env.PUBLIC_URL}/audio/slide_33.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/33.png`
    },
    {
      title: 'Lesson 34',
      src: `${process.env.PUBLIC_URL}/audio/slide_34.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/34.png`
    },
    {
      title: 'Lesson 35',
      src: `${process.env.PUBLIC_URL}/audio/slide_35.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/35.png`
    },
    {
      title: 'Lesson 36',
      src: `${process.env.PUBLIC_URL}/audio/slide_36.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/36.png`
    },
    {
      title: 'Lesson 37',
      src: `${process.env.PUBLIC_URL}/audio/slide_37.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/37.png`
    },
    {
      title: 'Lesson 38',
      src: `${process.env.PUBLIC_URL}/audio/slide_38.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/38.png`
    },
    {
      title: 'Lesson 39',
      src: `${process.env.PUBLIC_URL}/audio/slide_39.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/39.png`
    },
    {
      title: 'Lesson 40',
      src: `${process.env.PUBLIC_URL}/audio/slide_40.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/40.png`
    },
    {
      title: 'Lesson 41',
      src: `${process.env.PUBLIC_URL}/audio/slide_41.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/41.png`
    },
    {
      title: 'Lesson 42',
      src: `${process.env.PUBLIC_URL}/audio/slide_42.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/42.png`
    },
    {
      title: 'Lesson 43',
      src: `${process.env.PUBLIC_URL}/audio/slide_43.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/43.png`
    },
    {
      title: 'Lesson 44',
      src: `${process.env.PUBLIC_URL}/audio/slide_44.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/44.png`
    },
    {
      title: 'Lesson 45',
      src: `${process.env.PUBLIC_URL}/audio/slide_45.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/45.png`
    },
    {
      title: 'Lesson 46',
      src: `${process.env.PUBLIC_URL}/audio/slide_46.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/46.png`
    },
    {
      title: 'Lesson 47',
      src: `${process.env.PUBLIC_URL}/audio/slide_47.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/47.png`
    },
    {
      title: 'Lesson 48',
      src: `${process.env.PUBLIC_URL}/audio/slide_48.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/48.png`
    },
    {
      title: 'Lesson 49',
      src: `${process.env.PUBLIC_URL}/audio/slide_49.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/49.png`
    },
    {
      title: 'Lesson 50',
      src: `${process.env.PUBLIC_URL}/audio/slide_50.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/50.png`
    },
    {
      title: 'Lesson 51',
      src: `${process.env.PUBLIC_URL}/audio/slide_51.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/51.png`
    },
    {
      title: 'Lesson 52',
      src: `${process.env.PUBLIC_URL}/audio/slide_52.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/52.png`
    },
    {
      title: 'Lesson 53',
      src: `${process.env.PUBLIC_URL}/audio/slide_53.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/53.png`
    },
    {
      title: 'Lesson 54',
      src: `${process.env.PUBLIC_URL}/audio/slide_54.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/54.png`
    },
    {
      title: 'Lesson 55',
      src: `${process.env.PUBLIC_URL}/audio/slide_55.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/55.png`
    },
    {
      title: 'Lesson 56',
      src: `${process.env.PUBLIC_URL}/audio/slide_56.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/56.png`
    },
    {
      title: 'Lesson 57',
      src: `${process.env.PUBLIC_URL}/audio/slide_57.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/57.png`
    },
    {
      title: 'Lesson 58',
      src: `${process.env.PUBLIC_URL}/audio/slide_58.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/58.png`
    },
    {
      title: 'Lesson 59',
      src: `${process.env.PUBLIC_URL}/audio/slide_59.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/59.png`
    },
    {
      title: 'Lesson 60',
      src: `${process.env.PUBLIC_URL}/audio/slide_60.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/60.png`
    },
    {
      title: 'Lesson 61',
      src: `${process.env.PUBLIC_URL}/audio/slide_61.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/61.png`
    },
    {
      title: 'Lesson 62',
      src: `${process.env.PUBLIC_URL}/audio/slide_62.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/62.png`
    },
    {
      title: 'Lesson 63',
      src: `${process.env.PUBLIC_URL}/audio/slide_63.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/63.png`
    },
    {
      title: 'Lesson 64',
      src: `${process.env.PUBLIC_URL}/audio/slide_64.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/64.png`
    },
    {
      title: 'Lesson 65',
      src: `${process.env.PUBLIC_URL}/audio/slide_65.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/65.png`
    },
    {
      title: 'Lesson 66',
      src: `${process.env.PUBLIC_URL}/audio/slide_66.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/66.png`
    },
    {
      title: 'Lesson 67',
      src: `${process.env.PUBLIC_URL}/audio/slide_67.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/67.png`
    },
    {
      title: 'Lesson 68',
      src: `${process.env.PUBLIC_URL}/audio/slide_68.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/68.png`
    },
    {
      title: 'Lesson 69',
      src: `${process.env.PUBLIC_URL}/audio/slide_69.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/69.png`
    },
    {
      title: 'Lesson 70',
      src: `${process.env.PUBLIC_URL}/audio/slide_70.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/70.png`
    },
    {
      title: 'Lesson 71',
      src: `${process.env.PUBLIC_URL}/audio/slide_71.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/71.png`
    },
    {
      title: 'Lesson 72',
      src: `${process.env.PUBLIC_URL}/audio/slide_72.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/72.png`
    },
    {
      title: 'Lesson 73',
      src: `${process.env.PUBLIC_URL}/audio/slide_73.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/73.png`
    },
    {
      title: 'Lesson 74',
      src: `${process.env.PUBLIC_URL}/audio/slide_74.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/74.png`
    },
    {
      title: 'Lesson 75',
      src: `${process.env.PUBLIC_URL}/audio/slide_75.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/75.png`
    },
    {
      title: 'Lesson 76',
      src: `${process.env.PUBLIC_URL}/audio/slide_76.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/76.png`
    },
    {
      title: 'Lesson 77',
      src: `${process.env.PUBLIC_URL}/audio/slide_77.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/77.png`
    },
    {
      title: 'Lesson 78',
      src: `${process.env.PUBLIC_URL}/audio/slide_78.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/78.png`
    },
    {
      title: 'Lesson 79',
      src: `${process.env.PUBLIC_URL}/audio/slide_79.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/79.png`
    },
    {
      title: 'Lesson 80',
      src: `${process.env.PUBLIC_URL}/audio/slide_80.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/80.png`
    },
    {
      title: 'Lesson 81',
      src: `${process.env.PUBLIC_URL}/audio/slide_81.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/81.png`
    },
    {
      title: 'Lesson 82',
      src: `${process.env.PUBLIC_URL}/audio/slide_82.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/82.png`
    },
    {
      title: 'Lesson 83',
      src: `${process.env.PUBLIC_URL}/audio/slide_83.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/83.png`
    },
    {
      title: 'Lesson 84',
      src: `${process.env.PUBLIC_URL}/audio/slide_84.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/84.png`
    },
    {
      title: 'Lesson 85',
      src: `${process.env.PUBLIC_URL}/audio/slide_85.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/85.png`
    },
    {
      title: 'Lesson 86',
      src: `${process.env.PUBLIC_URL}/audio/slide_86.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/86.png`
    },
    {
      title: 'Lesson 87',
      src: `${process.env.PUBLIC_URL}/audio/slide_87.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/87.png`
    },
    {
      title: 'Lesson 88',
      src: `${process.env.PUBLIC_URL}/audio/slide_88.mp3`,
      img: `${process.env.PUBLIC_URL}/img/couseimg/slide/88.png`
    },
  ];

  const { title, src, img } = audioTracks[currentLessonIndex];
  const audioProgress = (currentTime / duration) * 100;

 
  const navigate = useNavigate();
  var rounded = Math.floor(progress)

  return (
    <>
       
        <>
          <Heroallcourse />
          <Container className="mt-4">
            <Row>
              <Col md={4} className="mb-4 mx-auto coursecard">
                <Card>
                  <Card.Img variant="top" src={img} alt={`Image for ${title}`} />
                  <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <audio ref={audioRef} key={src} controls={false} className="w-100">
                      <source src={src} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                    <div className="progress-bar-container">
                      <ProgressBar
                        now={audioProgress}
                        label={`${Math.round(audioProgress)}%`}
                        variant={audioProgress === 100 ? 'success' : 'primary'}
                        style={{ height: '10px' }}
                      />
                    </div>
                    <Button
                      variant="primary"
                      className="mt-2"
                      onClick={handlePlayPause}
                    >
                      {isPlaying ? 'Pause' : 'Play'}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <div style={{ padding: '20px', textAlign: 'center', marginTop: '20px' }}>
            <h3>Course Progress</h3>
            <ProgressBar 
              now={progress} 
              label={`${rounded}%`} 
              variant={progress === 100 ? 'success' : 'primary'}
            />
            <h4>Your last course Progress</h4>
            <ProgressBar 
            style={{height: '10px'}}
              now={progresss} 
              label={`${Math.floor(progresss)}%`} 
              variant={progresss === 100 ? 'success' : 'primary'}
            />
            <div style={{ marginTop: '20px' }}>
              {currentLessonIndex > 0 && (
                <Button onClick={handlePreviousLesson} style={{ marginRight: '10px' }}>
                  Previous
                </Button>
              )}
              {currentLessonIndex  < totalLessons - 1 && (progresss / 100) * totalLessons - 1 < totalLessons - 1 ? (
                <Button onClick={handleNextLesson} disabled={!currentTrackEnded}>
                  Next Lesson
                </Button>
              ) : (
                <Link to='/Quiz'><Button>
                  Start Quizzes
                </Button></Link>
              )}
            </div>
          </div>
        </>
      
    
    </>
  );
};

export default CourseProgress;
