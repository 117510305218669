import React, { useState } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import Register from './Register';

import AuthService from '../components/AuthService';
import Heroall from './heroall';

const Login = () => {
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the process starts
    AuthService.login(username, password).then(
      () => {
        setLoading(false); // Set loading to false when the process ends
        navigate('/');
        window.location.reload();
      },
      error => {
        setLoading(false); // Set loading to false when the process ends
        setMessage('Login failed. Check username and password.');
      }
    );
  };
  const [showModal, setShowModal] = useState(false);
  const [isRegister, setIsRegister] = useState(true);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const toggleForm = () => setIsRegister(!isRegister);
  
  const currentUser = AuthService.getCurrentUser();

  return (
    <>
      {!currentUser ? (
        <>
          <Heroall/>
          <Form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '30px auto' }}>
            <h2 className="text-center mb-4">Log In</h2>
            
            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="username"
                placeholder="User Name"
                name="email"
                value={username}
                onChange={e => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                required
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Group>
            {message && <div className="alert alert-danger" role="alert">{message}</div>}
            <Button variant="primary" type="submit" className="w-100" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Log In'}
            </Button>
            <Button onClick={()=>{handleShow()}} className='w-100 register'>Register</Button>
            <Button onClick={()=>{navigate('/reset-password')}} className='resetp '>Forgot Password?</Button>
          </Form>
        </>
      ) : (
        navigate('/')
      )}
      <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{isRegister ? 'Register' : 'Log In'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {isRegister ? <Register /> : <Login />}
    </Modal.Body>
    <Modal.Footer>
      <Button className='nooryesacount' variant="secondary" onClick={toggleForm}>
        {isRegister ? 'Have Account Log In' : 'No Account Register'}
      </Button>
      <Button variant="primary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
    </>
  );
};

export default Login;
