// CheckoutFormModal.js
import React, { useState } from 'react';
import { Modal, Form, Button, Col, Row, Alert, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CheckoutFormModal = ({ show, handleClose, amount, course }) => {
  const [validated, setValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowError(true);
    } else {
      setShowError(false);
    }
    setValidated(true);
  };

  const isCardNumberValid = (cardNumber) => {
    const regex = /^[0-9]{16}$/;
    return regex.test(cardNumber);
  };

  const isCVVValid = (cvv) => {
    const regex = /^[0-9]{3,4}$/;
    return regex.test(cvv);
  };

  const isExpiryDateValid = (expiryDate) => {
    const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    return regex.test(expiryDate);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Checkout For The {course} Course </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showError && (
          <Alert variant="danger">
            Please fill in all required fields correctly.
          </Alert>
        )}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <h4 className="mb-3">Payment Method, Amount to be paid: {amount}</h4>
          
          <Form.Group>
            <Form.Check
              type="radio"
              label="Visa Card"
              name="paymentMethod"
              id="visa"
              onChange={() => setPaymentMethod('visa')}
            />
            <Form.Check
              type="radio"
              label="Mobile Money"
              name="paymentMethod"
              id="mobile"
              onChange={() => setPaymentMethod('mobile')}
            />
          </Form.Group>

          {paymentMethod === 'visa' && (
            <>
              <h4 className="mb-3">Visa Card Payment</h4>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="formCardNumber">
                  <Form.Label>Card Number</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter card number"
                    pattern="[0-9]{16}"
                    onChange={(e) => setValidated(isCardNumberValid(e.target.value))}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid 16-digit card number.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="formExpiryDate">
                  <Form.Label>Expiry Date </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="MM/YY"
                    pattern="^(0[1-9]|1[0-2])\/?([0-9]{2})$"
                    onChange={(e) => setValidated(isExpiryDateValid(e.target.value))}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid expiry date.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="formCVV">
                  <Form.Label>CVV</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="CVV"
                    pattern="[0-9]{3,4}"
                    onChange={(e) => setValidated(isCVVValid(e.target.value))}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid CVV.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </>
          )}

          {paymentMethod === 'mobile' && (
            <>
              <h4 className="mb-3">Mobile Money Payment</h4>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="formMobileNumber">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter mobile number"
                    pattern="[0-9]{10,12}"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid mobile number.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="formOperator">
                  <Form.Label>Operator</Form.Label>
                  <Form.Control as="select" required defaultValue="">
                    <option value="">Choose...</option>
                    
                    <option>MTN</option>
                    <option>AIRTELL</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select an operator.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </>
          )}

          <div className="d-flex justify-content-center">
            
           <Link to='/mycourse'> <Button variant="primary" type="submit">
              Checkout
            </Button></Link>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CheckoutFormModal;
