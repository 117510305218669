import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Heroall from './heroall';

const PasswordResetRequestForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleClose = () => {
    setShowModal(false);
    navigate('/loginall'); // Replace '/some-other-page' with your desired path
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post('https://djangomyaip.hoseconsultsugandaltd.com/api/password_reset/', { email });
      setMessage('Password reset email sent successfully. Check your email.');
      setError('');
      setEmailSent(true);
    } catch (err) {
      setError('Error sending password reset email.');
      setMessage('');
      setEmailSent(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Heroall />
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && <Alert variant="success">{message}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          {!emailSent ? (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="custom-input"
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="custom-button" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {' Please wait...'}
                  </>
                ) : (
                  'Reset Password'
                )}
              </Button>
            </Form>
          ) : (
            <Button variant="primary" onClick={handleClose} className="custom-button">
              Close
            </Button>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PasswordResetRequestForm;
