import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {  Button, Modal } from 'react-bootstrap';
import AuthService from '../components/AuthService';
import { useNavigate } from 'react-router-dom';
import Register from './Register';
import Login from './Login';

const NavMenu = () => {
  const [activeSection, setActiveSection] = useState('#hero');
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);
  const [ishoved, sethoved] = useState(false);
  
  const navigate = useNavigate();

  const handleLogout = () => {
      AuthService.logout();
      AuthService.logout();
      navigate('/loginall');
      
};

  const handleSetActive = (section) => {
    setActiveSection(section);
    if (isMobileNavActive) {
      toggleMobileNav();
    }
  };

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
  };

  const mouseentre = () => {
    sethoved(true);
  };
  const mouseleave = () => {
    sethoved(false);
  };
  
  const [showModal, setShowModal] = useState(false);
  const [isRegister, setIsRegister] = useState(true);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const toggleForm = () => setIsRegister(!isRegister);

  const currentUser = AuthService.getCurrentUser();
  

  return (
    <>
    <nav id="navmenu" className='navmenu'>
      {!currentUser ? (
        <ul className={`navmenu ${isMobileNavActive ? 'mobile-nav-active' : ''}`}>
          <li>
            <Link to='/' className={activeSection === '#hero' ? 'active' : ''} onClick={() => handleSetActive('#hero')}>Home</Link>
          </li>
          <li>
            <Link to='/about' className={activeSection === '#about' ? 'active' : ''} onClick={() => handleSetActive('#about')}>About</Link>
          </li>
          <li>
            <Link to='/services' className={activeSection === '#services' ? 'active' : ''} onClick={() => handleSetActive('#services')}>Services</Link>
          </li>
          <li>
            <Link to='/couses' className={activeSection === '#portfolio' ? 'active' : ''} onClick={() => handleSetActive('#portfolio')}>Courses</Link>
          </li>
          <li>
            <Link to='/team' className={activeSection === '#team' ? 'active' : ''} onClick={() => handleSetActive('#team')}>Team</Link>
          </li>
          <li>
            <Link to='/blog' className={activeSection === '#blog' ? 'active' : ''} onClick={() => handleSetActive('#blog')}>Blog</Link>
          </li>
          <li>
            <Link  className={activeSection === '#blog' ? 'active' : ''} onClick={handleShow}>Register</Link>
          </li>
          <li className='mouse' onMouseEnter={mouseentre} onMouseLeave={mouseleave}>
            <Link to="/loginall" className={activeSection === '#/loginall' ? 'active' : ''} onClick={() => handleSetActive('#/loginall')} ><span>Login</span> {ishoved ? <i className="bi bi-unlock-fill"></i> : <i className="bi bi-lock-fill"></i>}</Link>
          </li>
          <li>
            <Link to='/contact' className={activeSection === '#contact' ? 'active' : ''} onClick={() => handleSetActive('#contact')}>Contact</Link>
          </li>
        </ul>
      ) : (
        <ul className={`navmenu userline ${isMobileNavActive ? 'mobile-nav-active' : ''}`}>
          <li>
            <Link to='/mycourse' className={activeSection === '#blog' ? 'active' : ''} onClick={() => handleSetActive('#blog')}>My Couses</Link>
          </li>
          <li>
            <Link to='/' className={activeSection === '#hero' ? 'active' : ''} onClick={() => handleSetActive('#hero')}>Courses</Link>
          </li>
          <li>
            <Link to='/profile' className={activeSection === '#about' ? 'active' : ''} onClick={() => handleSetActive('#about')}>My Account</Link>
          </li>
          <li className='mouse' onMouseEnter={mouseentre} onMouseLeave={mouseleave}>
            <a href="#" onClick={handleLogout}><span>Logout</span> {ishoved ? <i className="bi bi-lock-fill"></i> : <i className="bi bi-unlock-fill"></i>}</a>
          </li>
          <li>
            <Link to='/contact' className={activeSection === '#contact' ? 'active' : ''} onClick={() => handleSetActive('#contact')}>Contact</Link>
          </li>
        </ul>
      )}
      <i className={`mobile-nav-toggle d-xl-none bi ${isMobileNavActive ? 'bi-x' : 'bi-list'}`} onClick={toggleMobileNav}></i>
    </nav>
    <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{isRegister ? 'Register' : 'Log In'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {isRegister ? <Register /> : <Login />}
    </Modal.Body>
    <Modal.Footer>
      <Button className='nooryesacount' variant="secondary" onClick={toggleForm}>
        {isRegister ? 'Have Account Log In' : 'No Account Register'}
      </Button>
      <Button variant="primary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  </>
  );
};

export default NavMenu;
