
import React, { useRef } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Client() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
    
    <div className="clients section">
    <div className="container">
    <div class="container section-title" data-aos="fade-up">
        <h2>Our Clients<br/></h2>
      </div>
        <div className="swiper init-swiper" >
      
      <Slider
        ref={slider => {
          sliderRef = slider;
        }}
        {...settings}
        className="swiper-wrapper align-items-center"
      >
        
        <div className='swiper-slide'>
          <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/clients/client-1.png`} alt=''></img>
          
          
        </div>
        <div className='swiper-slide'>
          <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/clients/client-2.png`}  alt=''></img>
          
          
        </div>
        <div className='swiper-slide'>
          <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/clients/client-3.jpg`}  alt=''></img>
          
          
        </div>
        <div  className='swiper-slide'>
          <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/clients/client-4.png`}  alt=''></img>
          
          
        </div>
        
        <div className='swiper-slide'>
          <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/clients/client-7.jpg`}  alt=''></img>
          
          
        </div>
        <div className='swiper-slide'>
          <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/clients/front.svg`}  alt=''></img>
        </div>
        <div className='swiper-slide'>
          <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/clients/kyoga.jpg`}  alt=''></img>
        </div>
        
      </Slider>
      </div>
      </div>
    </div>
    </>
  );
}

export default Client;