import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure you have Bootstrap Icons installed and imported
import Heroall from './heroall';
import ISOServices from './isos';

const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    <Heroall/>
    
    <section id="services" className="services section">
      
    <ISOServices/>
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-activity"></i>
              </div>
              <h3>HR recruitment services/Consultancy</h3>
              <p>Hose Consults Uganda Ltd offers HR recruitment services because we believe in human capital as the best and basic resource in any organization....</p>
              <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="150">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-broadcast"></i>
              </div>
              <h3>Psychosocial support & Counselling</h3>
              <p>This is done through our specialist team that does inspection/risk assessment and auditing to verify the integrity of different machines...</p>
              <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-easel"></i>
              </div>
              <h3>Evaluation and Employee engagement</h3>
              <p>Implications due to failure to address government laws/acts and regulations. Hose Consults Uganda Ltd was formed to help clients and or students...</p>
              <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-bounding-box-circles"></i>
              </div>
              <h3>Public and motivational speaking</h3>
              <p>We offer these vital services to our clients. We encourage our clients to visit our website for more services...</p>
              <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-calendar4-week"></i>
              </div>
              <h3>Entrepreneurship skills and training</h3>
              <p>We offer trainings in entrepreneurial fields to help students/clients become self-reliant and earn a living besides the job salary or wages...</p>
              <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-chat-square-text"></i>
              </div>
              <h3>Capacity building training</h3>
              <p>Fire safety trainings, First aid training at workplace, buildings fire safety suppression installation...</p>
              <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Services;
