import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import AuthService from '../components/AuthService';
import Heroallcourse from './Herocourse';

const UserProfileForm = () => {
    const [userData, setUserData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        profile: {
            profile_img: null,
            city: '',
            state: '',
            country: '',
            phone_number: '',
            zip_code: '',
            active:''
        }
    });
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        AuthService.getProfile()
            .then(data => {
                setUserData({
                    ...data,
                    profile: data.profile || {
                        profile_img: null,
                        city: '',
                        state: '',
                        country: '',
                        phone_number: '',
                        zip_code: '',
                        active:''
                    }
                });
            })
            .catch(err => {
                setErrors(prevState => ({
                    ...prevState,
                    general: 'Failed to fetch user data.'
                }));
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('profile.')) {
            const profileField = name.split('.')[1];
            setUserData(prevState => ({
                ...prevState,
                profile: {
                    ...prevState.profile,
                    [profileField]: value
                }
            }));
        } else {
            setUserData(prevState => ({ ...prevState, [name]: value }));
        }
        setErrors(prevState => ({ ...prevState, [name]: null })); // Clear error when user types
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setUserData(prevState => ({
            ...prevState,
            profile: {
                ...prevState.profile,
                profile_img: file
            }
        }));
        setErrors(prevState => ({ ...prevState, 'profile.profile_img': null })); // Clear error when user selects file
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};

        // Check for empty fields
        if (!userData.username) newErrors.username = 'Username is required.';
        if (!userData.first_name) newErrors.first_name = 'First Name is required.';
        if (!userData.last_name) newErrors.last_name = 'Last Name is required.';
        if (!userData.email) newErrors.email = 'Email is required.';
        if (!userData.profile.city) newErrors['profile.city'] = 'City is required.';
        if (!userData.profile.state) newErrors['profile.state'] = 'State is required.';
        if (!userData.profile.country) newErrors['profile.country'] = 'Country is required.';
        if (!userData.profile.phone_number) newErrors['profile.phone_number'] = 'Phone Number is required.';
        if (!userData.profile.zip_code) newErrors['profile.zip_code'] = 'Zip Code is required.';

        // Validate username length
        if (userData.username && userData.username.length < 8) {
            newErrors.username = 'Username must be at least 8 characters long.';
        }

        // Validate phone number
        const phoneNumberPattern = /^[0-9]{1,13}$/;
        if (userData.profile.phone_number && !phoneNumberPattern.test(userData.profile.phone_number)) {
            newErrors['profile.phone_number'] = 'Phone Number must be numbers only and not more than 13 digits.';
        }

        // Validate zip code
        const zipCodePattern = /^[0-9]{1,5}$/;
        if (userData.profile.zip_code && !zipCodePattern.test(userData.profile.zip_code)) {
            newErrors['profile.zip_code'] = 'Zip Code must be numbers only and not more than 5 digits.';
        }

        // Validate fields to be letters only
        const lettersOnlyPattern = /^[A-Za-z]+$/;
        if (userData.username && !lettersOnlyPattern.test(userData.username)) {
            newErrors.username = 'Username must contain letters only.';
        }
        if (userData.first_name && !lettersOnlyPattern.test(userData.first_name)) {
            newErrors.first_name = 'First Name must contain letters only.';
        }
        if (userData.last_name && !lettersOnlyPattern.test(userData.last_name)) {
            newErrors.last_name = 'Last Name must contain letters only.';
        }
        if (userData.profile.city && !lettersOnlyPattern.test(userData.profile.city)) {
            newErrors['profile.city'] = 'City must contain letters only.';
        }
        if (userData.profile.state && !lettersOnlyPattern.test(userData.profile.state)) {
            newErrors['profile.state'] = 'State must contain letters only.';
        }
        if (userData.profile.country && !lettersOnlyPattern.test(userData.profile.country)) {
            newErrors['profile.country'] = 'Country must contain letters only.';
        }

        setErrors(newErrors);

        // If there are errors, do not submit the form
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        const formData = new FormData();
        formData.append('username', userData.username);
        formData.append('first_name', userData.first_name);
        formData.append('last_name', userData.last_name);
        formData.append('email', userData.email);
        if (userData.profile.profile_img) {
            formData.append('profile.profile_img', userData.profile.profile_img);
        }
        formData.append('profile.city', userData.profile.city);
        formData.append('profile.state', userData.profile.state);
        formData.append('profile.country', userData.profile.country);
        formData.append('profile.phone_number', userData.profile.phone_number);
        formData.append('profile.zip_code', userData.profile.zip_code);

        AuthService.updateProfile(formData)
            .then(() => {
                setSuccess('Profile updated successfully.');
                setErrors({}); // Clear any previous errors
            })
            .catch(() => setErrors(prevState => ({
                ...prevState,
                general: 'Failed to update profile.'
            })));
    };

    return (
        <>
            <Heroallcourse />
            <Container>
                <h2 className="mb-4">Update Profile</h2>
                {errors.general && <Alert variant="danger">{errors.general}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="formUsername">
                        <Form.Label column sm={2}>Username</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="username"
                                value={userData.username}
                                onChange={handleInputChange}
                                minLength={8} // Minimum length validation
                                required
                                isInvalid={!!errors.username}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.username}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formFirstName">
                        <Form.Label column sm={2}>First Name</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="first_name"
                                value={userData.first_name}
                                onChange={handleInputChange}
                                required
                                isInvalid={!!errors.first_name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.first_name}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formLastName">
                        <Form.Label column sm={2}>Last Name</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="last_name"
                                value={userData.last_name}
                                onChange={handleInputChange}
                                required
                                isInvalid={!!errors.last_name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.last_name}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formEmail">
                        <Form.Label column sm={2}>Email</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={handleInputChange}
                                required
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formProfileImg">
                        <Form.Label column sm={2}>Profile Image</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="file"
                                name="profile.profile_img"
                                onChange={handleImageChange}
                                isInvalid={!!errors['profile.profile_img']}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors['profile.profile_img']}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formCity">
                        <Form.Label column sm={2}>City</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="profile.city"
                                value={userData.profile.city}
                                onChange={handleInputChange}
                                required
                                isInvalid={!!errors['profile.city']}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors['profile.city']}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formState">
                        <Form.Label column sm={2}>State</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="profile.state"
                                value={userData.profile.state}
                                onChange={handleInputChange}
                                required
                                isInvalid={!!errors['profile.state']}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors['profile.state']}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formCountry">
                        <Form.Label column sm={2}>Country</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="profile.country"
                                value={userData.profile.country}
                                onChange={handleInputChange}
                                required
                                isInvalid={!!errors['profile.country']}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors['profile.country']}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPhoneNumber">
                        <Form.Label column sm={2}>Phone Number</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="profile.phone_number"
                                value={userData.profile.phone_number}
                                onChange={handleInputChange}
                                required
                                isInvalid={!!errors['profile.phone_number']}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors['profile.phone_number']}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formZipCode">
                        <Form.Label column sm={2}>Zip Code</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="profile.zip_code"
                                value={userData.profile.zip_code}
                                onChange={handleInputChange}
                                required
                                isInvalid={!!errors['profile.zip_code']}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors['profile.zip_code']}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Update Profile
                    </Button>
                </Form>
            </Container>
        </>
    );
};

export default UserProfileForm;
