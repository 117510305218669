import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Container, Row, Col, Nav, Tab, Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Heroall from './heroall';
import Register from './Register';
import Login from './Login';
import { Link } from 'react-router-dom';
import CheckoutFormModal from './Checkout';
import AuthService from '../components/AuthService';
import { useNavigate } from 'react-router-dom';



const CourseDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isRegister, setIsRegister] = useState(true);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [checkShowModal, setCheckShowModal] = useState(false);
  const checkoutShowModal = () => setCheckShowModal(true);
  const checkoutClose  = () => setCheckShowModal(false);

  const toggleForm = () => setIsRegister(!isRegister);
 
  const ispaid =()=>{
    navigate('/mycourse');
    navigate('/mycourse');
  }
  
  const [userData, setUserData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    profile: {
        profile_img: null,
        city: '',
        state: '',
        country: '',
        phone_number: '',
        zip_code: '',
        active:''
    }
});
const [errors, setErrors] = useState({});
const [success, setSuccess] = useState(null);

useEffect(() => {
    AuthService.getProfile()
        .then(data => {
            setUserData({
                ...data,
                profile: data.profile || {
                    profile_img: null,
                    city: '',
                    state: '',
                    country: '',
                    phone_number: '',
                    zip_code: '',
                    active:''
                }
            });
        })
        .catch(err => {
            setErrors(prevState => ({
                ...prevState,
                general: 'Failed to fetch user data.'
            }));
        });
}, []);

const paid = userData.profile.active

  const currentUser = AuthService.getCurrentUser();
  return (
    <>
      <Heroall/>
      <section id="courses-course-details" className="courses-course-details section">
        <Container data-aos="fade-up">
          <Row>
            <Col lg={8}>
              <img
                src={`${process.env.PUBLIC_URL}/img/blog/front.png`}
                className="img-fluid"
                alt="First Aid Course"
              />
              <h3>Comprehensive First Aid Training</h3>
              <p>
                This First Aid course provides essential skills to handle emergencies and save lives. Participants will learn to assess situations, provide basic life support, manage wounds, and address various medical conditions.
                This training is crucial for anyone who wants to be prepared for emergencies, whether at home, work, or in the community.
              </p>
            </Col>
            <Col lg={4}>
              <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Trainer</h5>
                <p><a href="#">Godfrey Wamboga</a></p>
              </div>
              <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Course Fee</h5>
                <p>$50</p>
              </div>
              <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Course Hours</h5>
                <p>2hr</p>
              </div>
              <div className="text-center get">
                {paid? <Button className="buy-btn getstart" onClick={ispaid}>Starts Now</Button>:
               currentUser ?<Button className="buy-btn getstart" onClick={()=>{window.location.assign('https://buy.stripe.com/test_3cs16f5bj9go9mE8ww')}}>Start Now</Button>:
                <Button className="buy-btn getstart" onClick={handleShow}>Start Now</Button>
                }
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="tabs" className="tabs section">
        <Container data-aos="fade-up" data-aos-delay="100">
          <Tab.Container defaultActiveKey="tab-1">
            <Row>
              <Col lg={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="tab-1">Introduction to First Aid</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-2">Basic Life Support</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-3">Wound Management</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-4">Medical Emergencies</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-5">Practical Skills</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={9} className="mt-4 mt-lg-0">
                <Tab.Content>
                  <Tab.Pane eventKey="tab-1">
                    <Row>
                      <Col lg={8} className="details order-2 order-lg-1">
                        <h3>Introduction to First Aid</h3>
                        <p className="fst-italic">Understanding the importance of First Aid and its applications in various situations.</p>
                        <p>This section covers the fundamentals of First Aid, including its significance, when and how to apply it, and the basic principles that guide effective First Aid practices. Participants will gain an overview of the course and what to expect in subsequent modules.</p>
                      </Col>
                      <Col lg={4} className="text-center order-1 order-lg-2">
                        <img src={`${process.env.PUBLIC_URL}/img/couseimg/first.svg`} alt="Introduction to First Aid" className="img-fluid" />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-2">
                    <Row>
                      <Col lg={8} className="details order-2 order-lg-1">
                        <h3>Basic Life Support</h3>
                        <p className="fst-italic">Learn critical life-saving techniques such as CPR and how to use an AED.</p>
                        <p>This section teaches participants how to provide Basic Life Support, including Cardiopulmonary Resuscitation (CPR) and the use of Automated External Defibrillators (AED). These skills are essential for responding to cardiac emergencies and increasing the chances of survival for victims.</p>
                      </Col>
                      <Col lg={4} className="text-center order-1 order-lg-2">
                        <img src={`${process.env.PUBLIC_URL}/img/couseimg/second.svg`} alt="Basic Life Support" className="img-fluid" />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-3">
                    <Row>
                      <Col lg={8} className="details order-2 order-lg-1">
                        <h3>Wound Management</h3>
                        <p className="fst-italic">Effective techniques for managing cuts, burns, and other injuries.</p>
                        <p>This module covers the various types of wounds, how to assess their severity, and the appropriate First Aid responses. Participants will learn how to clean, dress, and manage different wounds, including minor cuts, severe lacerations, and burns.</p>
                      </Col>
                      <Col lg={4} className="text-center order-1 order-lg-2">
                        <img src={`${process.env.PUBLIC_URL}/img/couseimg/therd.svg`} alt="Wound Management" className="img-fluid" />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-4">
                    <Row>
                      <Col lg={8} className="details order-2 order-lg-1">
                        <h3>Medical Emergencies</h3>
                        <p className="fst-italic">Handling common medical emergencies such as choking, allergic reactions, and shock.</p>
                        <p>This section provides guidance on how to manage various medical emergencies. Participants will learn to recognize the signs and symptoms of conditions like choking, severe allergic reactions, shock, and how to provide immediate and effective First Aid in these situations.</p>
                      </Col>
                      <Col lg={4} className="text-center order-1 order-lg-2">
                        <img src={`${process.env.PUBLIC_URL}/img/couseimg/fourth.svg`} alt="Medical Emergencies" className="img-fluid" />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab-5">
                    <Row>
                      <Col lg={8} className="details order-2 order-lg-1">
                        <h3>Practical Skills</h3>
                        <p className="fst-italic">Hands-on practice of First Aid skills in realistic scenarios.</p>
                        <p>In this module, participants will engage in online exercises to apply the knowledge and skills learned throughout the course. Through simulations and hands-on practice, they will gain confidence in their ability to respond effectively in real-life emergencies.</p>
                      </Col>
                      <Col lg={4} className="text-center order-1 order-lg-2">
                        <img src={`${process.env.PUBLIC_URL}/img/couseimg/fifith.svg`} alt="Practical Skills" className="img-fluid" />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isRegister ? 'Register' : 'Log In'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isRegister ? <Register /> : <Login />}
        </Modal.Body>
        <Modal.Footer>
          <Button className='nooryesacount' variant="secondary" onClick={toggleForm}>
            {isRegister ? 'Have Account Log In' : 'No Account Register'}
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
      <CheckoutFormModal show={checkShowModal} handleClose={checkoutClose} amount={'$165'} course={'First AID'} />
      
    </>
  );
};

export default CourseDetails;
