import React, { useEffect, useRef, useState } from 'react';
import Isotope from 'isotope-layout';
import AOS from 'aos';
import GLightbox from 'glightbox';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.css';


const Portfolio = () => {
  const isotope = useRef();
  const grid = useRef();
  const [activeFilter, setActiveFilter] = useState('*');

  useEffect(() => {
    AOS.init();
    GLightbox({ selector: '.glightbox' });
    
    isotope.current = new Isotope(grid.current, {
      itemSelector: '.portfolio-item',
      layoutMode: 'masonry'
    });

    return () => {
      if (isotope.current) {
        isotope.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (isotope.current) {
      isotope.current.arrange({ filter: activeFilter });
    }
  }, [activeFilter]);

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <section id="portfolio" className="portfolio section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Portfolio</h2>
        <p>We are honest and truthful in all our actions at Hose Consults Uganda Limited</p>
      </div>

      <div className="container">
        <ul className="portfolio-filters isotope-filters" data-aos="fade-up" data-aos-delay="100">
          <li onClick={() => handleFilter('*')} className={activeFilter === '*' ? 'filter-active' : ''}>All</li>
          <li onClick={() => handleFilter('.filter-app')} className={activeFilter === '.filter-app' ? 'filter-active' : ''}>MACHINE</li>
          <li onClick={() => handleFilter('.filter-product')} className={activeFilter === '.filter-product' ? 'filter-active' : ''}>ENVIRONMENTAL</li>
          <li onClick={() => handleFilter('.filter-branding')} className={activeFilter === '.filter-branding' ? 'filter-active' : ''}>HEALTH</li>
          <li onClick={() => handleFilter('.filter-books')} className={activeFilter === '.filter-books' ? 'filter-active' : ''}>HR CONSULTANCY</li>
        </ul>

        <div className="row gy-4 isotope-container" ref={grid} data-aos="fade-up" data-aos-delay="200">
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <div className="portfolio-content h-100">
              <a  href={`${process.env.PUBLIC_URL}/img/portfolio/App-1.jpg`} data-gallery="portfolio-gallery-app" className="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/App-1.jpg`} className="img-fluid" alt="App 1" />
              </a>
              <div className="portfolio-info">
                <h4><a href="portfolio-details.html" title="More Details">MACHINE Training</a></h4>
                <p>Equipment and machinery safety operations training</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
              <div class="portfolio-content h-100">
                <a  href={`${process.env.PUBLIC_URL}/img/portfolio/product-1.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img  src={`${process.env.PUBLIC_URL}/img/portfolio/product-1.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">Environmental consultancy </a></h4>
                  <p>Environmental consultancy services trainings</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
              <div class="portfolio-content h-100">
                <a  href={`${process.env.PUBLIC_URL}/img/portfolio/branding-1.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img  src={`${process.env.PUBLIC_URL}/img/portfolio/branding-1.jpg`}  class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">HEALTHY</a></h4>
                  <p>Occupational Safety and health consultancy trainings</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-books">
              <div class="portfolio-content h-100">
                <a  href={`${process.env.PUBLIC_URL}/img/portfolio/books-1.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img  src={`${process.env.PUBLIC_URL}/img/portfolio/books-1.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">HR consultancy</a></h4>
                  <p>HR recruitment services/Consultancy.</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <div class="portfolio-content h-100">
                <a  href={`${process.env.PUBLIC_URL}/img/portfolio/App-2.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/App-2.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">MACHINE Training</a></h4>
                  <p>Equipment and machinery safety operations training</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
              <div class="portfolio-content h-100">
                <a href={`${process.env.PUBLIC_URL}/img/portfolio/product-2.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/product-2.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">Environmental consultancy</a></h4>
                  <p>Environmental consultancy services trainings</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
              <div class="portfolio-content h-100">
                <a href={`${process.env.PUBLIC_URL}/img/portfolio/branding-2.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/branding-2.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">HEALTHY</a></h4>
                  <p>Occupational Safety and health consultancy trainings</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-books">
              <div class="portfolio-content h-100">
                <a href={`${process.env.PUBLIC_URL}/img/portfolio/books-2.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/books-2.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">HR consultancy</a></h4>
                  <p>HR recruitment services/Consultancy.</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <div class="portfolio-content h-100">
                <a href={`${process.env.PUBLIC_URL}/img/portfolio/App-3.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/App-3.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">MACHINE Training</a></h4>
                  <p>Equipment and machinery safety operations training</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
              <div class="portfolio-content h-100">
                <a href={`${process.env.PUBLIC_URL}/img/portfolio/product-3.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/product-3.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">Environmental consultancy</a></h4>
                  <p>Environmental consultancy services trainings</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
              <div class="portfolio-content h-100">
                <a href={`${process.env.PUBLIC_URL}/img/portfolio/branding-3.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/branding-3.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">HEALTHY</a></h4>
                  <p>Occupational Safety and health consultancy trainings</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-books">
              <div class="portfolio-content h-100">
                <a href={`${process.env.PUBLIC_URL}/img/portfolio/books-3.jpg`} data-gallery="portfolio-gallery-app" class="glightbox">
                <img src={`${process.env.PUBLIC_URL}/img/portfolio/books-3.jpg`} class="img-fluid" alt=""/></a>
                <div class="portfolio-info">
                  <h4><a href="portfolio-details.html" title="More Details">HR consultancy</a></h4>
                  <p>HR recruitment services/Consultancy.</p>
                </div>
              </div>
            </div>
          {/* Repeat similar blocks for other portfolio items */}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
