import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import PureCounter from '@srexi/purecounterjs'; // Ensure you have PureCounter library installed and imported
import { useEffect } from 'react';

const StatsSection = () => {
  useEffect(() => {
    AOS.init();
    new PureCounter();
  }, []);

  return (
    <section id="stats" className="stats section">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4 align-items-center">
          <div className="col-lg-5">
            <img src={`${process.env.PUBLIC_URL}/img/stats-img.svg`} alt="" className="img-fluid" />
          </div>

          <div className="col-lg-7">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="stats-item d-flex">
                  <i className="bi bi-emoji-smile flex-shrink-0"></i>
                  <div>
                    <span 
                      data-purecounter-start="0" 
                      data-purecounter-end="232" 
                      data-purecounter-duration="3" 
                      className="purecounter"
                    ></span>
                    <p><strong>Happy Clients</strong> <span>With A Grateful heart</span></p>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-6">
                <div className="stats-item d-flex">
                  <i className="bi bi-journal-richtext flex-shrink-0"></i>
                  <div>
                    <span 
                      data-purecounter-start="0" 
                      data-purecounter-end="521" 
                      data-purecounter-duration="2" 
                      className="purecounter"
                    ></span>
                    <p><strong>Projects</strong> <span>With Well Aligned Work</span></p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="stats-item d-flex">
                  <i className="bi bi-headset flex-shrink-0"></i>
                  <div>
                    <span 
                      data-purecounter-start="0" 
                      data-purecounter-end="1453" 
                      data-purecounter-duration="2" 
                      className="purecounter"
                    ></span>
                    <p><strong>Hours Of Support</strong> <span>And Time Mobilization</span></p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="stats-item d-flex">
                  <i className="bi bi-people flex-shrink-0"></i>
                  <div>
                    <span 
                      data-purecounter-start="0" 
                      data-purecounter-end="32" 
                      data-purecounter-duration="1" 
                      className="purecounter"
                    ></span>
                    <p><strong>Hard Workers</strong> <span>With Assertive Minds </span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
