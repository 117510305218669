import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  useNavigate } from 'react-router-dom';

import AuthService from '../components/AuthService';

const Login = () => {
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the process starts
    AuthService.login(username, password).then(
      () => {
        setLoading(false); // Set loading to false when the process ends
        navigate('/');
        window.location.reload();
      },
      error => {
        setLoading(false); // Set loading to false when the process ends
        setMessage('Login failed. Check username and password.');
      }
    );
  };

  return (
    <>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '30px auto' }}>
        <h2 className="text-center mb-4">Log In</h2>
        
        <Form.Group controlId="userName" className="mb-3">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            type="username"
            placeholder="User Name"
            name="email"
            value={username}
            onChange={e => setUsername(e.target.value)}
            required={true}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword" className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            required={true}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>

        {message && <div className="alert alert-danger" role="alert">{message}</div>}
        
        <Button variant="primary" type="submit" className="w-100" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Log In'}
        </Button>
        <Button onClick={()=>{navigate('/reset-password')}} className='resetp'>Forgot Password?</Button>
      </Form>
    </>
  );
};

export default Login;
