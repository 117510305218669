import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';


const ISOServices = () => {
  return (
    <Container className="my-5 iso-services">
      <Row className="mb-4">
        <Col>
          <h2 className="text-center">ISO Auditing and Certification Services</h2>
          <p className="text-center">
            At HOSE Consults Uganda Limited, we support all our clients from small, medium, and large-scale organizations to achieve high standards and perfection in their processes.
          </p>
          <p className="text-center">
            We develop systems that support achieving ISO requirements, including:
          </p>
        </Col>
      </Row>
      <Row className="gy-4">
        <Col md={6} lg={4} className='backc'>
          <Card className="h-100 text-center service-card">
            <Card.Body>
              <Card.Title>Internal Audit System Training</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className='backc'>
          <Card className="h-100 text-center service-card">
            <Card.Body>
              <Card.Title>IMS Auditing Training Services</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className='backc'>
          <Card className="h-100 text-center service-card">
            <Card.Body>
              <Card.Title>
                ISO 45001:2018, 14001:2015, 9001:2015, 50001:2016, 31000, 22000:2018, PQCI, HACCP Training and Auditing Services
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className='backc'>
          <Card className="h-100 text-center service-card">
            <Card.Body>
              <Card.Title>Food Safety and Hygiene Awareness Training</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className='backc'>
          <Card className="h-100 text-center service-card">
            <Card.Body>
              <Card.Title>Implementor 22000 Training</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className='backc'>
          <Card className="h-100 text-center service-card">
            <Card.Body>
              <Card.Title>ISO certifications  (ISO 45001:2018)
(ISO 14001:2015)
(ISO 9001:2015)
(ISO 27001:2018)
Etc</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ISOServices;
