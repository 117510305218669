import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import Pricing from './pricing';
import Heroall from './heroall';
import { Link } from 'react-router-dom';




const CourseItem = ({ id, imgSrc, enroll, hover,  price, title, description, trainerImg, trainerName, userCount, likeCount }) => {
  return (
    <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
      <div className="course-item">
        <div className='mehover'>
          <img src={imgSrc} className="img-fluid meimg" alt="..." />
          <div className="meoverlay">
            <div className="mytext">{hover}</div>
          </div>
          <strong className='ribbon'>{price}</strong>
        </div>
        <div className="course-content">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="category enroll"><Link className='enroll' to={`/course-details/${id}`}>{enroll}</Link></p>
          </div>
          <h3><a href={`/course-details/${id}`}>{title}</a></h3>
          <p className="description">{description}</p>
          <div className="trainer d-flex justify-content-between align-items-center">
            <div className="trainer-profile d-flex align-items-center">
              <img src={trainerImg} className="img-fluid" alt="" />
              <a href="#" className="trainer-link">{trainerName}</a>
            </div>
            <div className="trainer-rank d-flex align-items-center">
              <i className="bi bi-person user-icon"></i>&nbsp;{userCount}
              &nbsp;&nbsp;
              <i className="bi bi-heart heart-icon"></i>&nbsp;{likeCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Courses = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const courseData = [
    {
      id: 1,
      imgSrc: `${process.env.PUBLIC_URL}/img/blog/blog-8.jpg`,
      category: "Fire Safety Course",
      price: "$50",
      title: "Fire Safety Course",
      description: "Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore.",
      trainerImg: "assets/img/trainers/trainer-1-2.jpg",
      trainerName: "Antonio",
      userCount: 50,
      enroll: "Enroll NOW",
      likeCount: 65,
      hover: "Fire Safety"
    },
    {
      id: 2,
      imgSrc: `${process.env.PUBLIC_URL}/img/couseimg/cours1.jpg`,
      category: "First Aid Course",
      price: "$50",
      title: "First Aid Training Course",
      description: "Describe the legal implications when giving first aid.",
      trainerImg: `${process.env.PUBLIC_URL}/img/team/team-1.jpg`,
      trainerName: "Wamboga",
      userCount: 35,
      enroll: "Enroll NOW",
      likeCount: 42,
      hover: "At the End of this First Aid Training Course Prevention, one must be in good position to know of the cardiovascular system. Define cardiovascular disease. Describe the risk factors of the cardiovascular diseases. Outline the preventive measures. Describe the first aid for cardiovascular emergencies...."
    },
    {
      id: 3,
      imgSrc: `${process.env.PUBLIC_URL}/img/blog/blog-9.jpg`,
      category: "Content",
      price: "$50",
      title: "Forklift Operator  Course",
      description: "Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore.",
      trainerImg: "assets/img/trainers/trainer-3-2.jpg",
      trainerName: "Brandon",
      userCount: 20,
      enroll: "Enroll NOW",
      likeCount: 85,
      hover: "Forklift Operator  Course"
    }
  ];



  

  return (
    <>
      <Heroall />
      <section id="courses" className="courses section">
        <div className="container">
          <div className="row">
            {courseData.map((course, index) => (
              <CourseItem key={index} {...course} />
            ))}
          </div>
        </div>
      </section>
      <Pricing />
    </>
  );
};

export default Courses;
