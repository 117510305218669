import React from 'react'
import Heroall from './heroall'

function Underconstraction() {
  return (
    <>
    <Heroall/>
    <h1>page 440</h1>
    <h2>This course is still under constraction </h2>
    <h3>Try again later</h3>
    </>
  )
}

export default Underconstraction