import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import AuthService from '../components/AuthService';

const Pricing = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const currentUser = AuthService.getCurrentUser();
  return (
    
    <section id="pricing" className="pricing section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Pricing</h2>
        <p>OSH, ENVIRONMENT, QUALITY, SECURITY, HR CONSULTANCY COURSES</p>
      </div>{/* End Section Title */}

      <div className="container" data-aos="zoom-in" data-aos-delay="100">
        <div className="row g-4">
          {/* Free Courses */}
          <div className="col-lg-4">
            <div className="pricing-item">
              <h3>Parsonal Course</h3>
              <div className="icon">
                <i className="bi bi-box"></i>
              </div>
              <h4><sup>$</sup>50<span> / Course</span></h4>
              <ul>
                <li><i className="bi bi-check"></i> <span>OSH Audit.</span></li>
                <li><i className="bi bi-check"></i> <span>Fire Safety Prevention and Fighting Training</span></li>
                <li><i className="bi bi-check"></i> <span>First Aid Training Course at workplace</span></li>
                
              </ul>
              {!currentUser ?<div className="text-center"><a href="loginall" className="buy-btn">Enrol Now</a></div>:
              <div className="text-center"><a href="" className="buy-btn">Enrol Now</a></div> }
            </div>
          </div>{/* End Pricing Item */}
          {/* Featured Business Courses */}
          <div className="col-lg-4">
            <div className="pricing-item featured">
              <h3>Business Courses</h3>
              <div className="icon">
                <i className="bi bi-rocket"></i>
              </div>
              <h4><sup>$</sup>50<span> / Course</span></h4>
              <ul>
                <li><i className="bi bi-check"></i> <span>OSH Audit.</span></li>
                <li><i className="bi bi-check"></i> <span>Fire Safety Prevention and Fighting Training</span></li>
                <li><i className="bi bi-check"></i> <span>First Aid Training Course at workplace</span></li>
                <li><i className="bi bi-check"></i> <span>Forklift Operator Training Certification Course.</span></li>
                <li><i className="bi bi-check"></i> <span>Mental Health First Aid Course (MHFA) at workplace</span></li>
              </ul>
              {!currentUser ?<div className="text-center"><a href="loginall" className="buy-btn">Enrol Now</a></div>:
              <div className="text-center"><a href="" className="buy-btn">Enrol Now</a></div> }
            </div>
          </div>{/* End Pricing Item */}
          {/* Company Courses */}
          <div className="col-lg-4">
            <div className="pricing-item">
              <h3>Company Courses</h3>
              <div className="icon">
                <i className="bi bi-send"></i>
              </div>
              <h4><sup>$</sup>50<span> / Course</span></h4>
              <ul>
                <li><i className="bi bi-check"></i> <span>OSH Audit.</span></li>
                <li><i className="bi bi-check"></i> <span>Fire Safety Prevention and Fighting Training</span></li>
                <li><i className="bi bi-check"></i> <span>First Aid Training Course at workplace</span></li>
                <li><i className="bi bi-check"></i> <span>Forklift Operator Training Certification Course.</span></li>
                <li><i className="bi bi-check"></i> <span>Mental Health First Aid Course (MHFA) at workplace</span></li>
                <li><i className="bi bi-check"></i> <span>Boiler operators’ technical Training and certification</span></li>
                <li><i className="bi bi-check"></i> <span>Compressed Gas Safety Management Training Course.</span></li>
                <li><i className="bi bi-check"></i> <span>Construction Safety and Management Training course.</span></li>
                <li><i className="bi bi-check"></i> <span>Hazardous Energy (LOTO) Procedures Training Course</span></li>
              </ul>
              {!currentUser ?<div className="text-center"><a href="loginall" className="buy-btn">Enrol Now</a></div>:
              <div className="text-center"><a href="" className="buy-btn">Enrol Now</a></div> }
            </div>
          </div>{/* End Pricing Item */}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
