import React, { useState } from 'react';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="faq" className="faq section">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="content px-xl-5">
              <h3><span>Frequently Asked </span><strong>Questions</strong></h3>
              <p>About a year ago::</p>
              
            <img src={`${process.env.PUBLIC_URL}/img/team/qesions.svg`} alt="" className="img-fluid" />
          
            </div>
          </div>
          <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
            <div className="faq-container">
              {[
                {
                  question: 'What services does your company offer?',
                  answer: 'Our company specializes in health and safety consulting, providing comprehensive training programs tailored to the specific needs of our clients. We offer risk assessments, safety audits, compliance assistance, and ongoing support to ensure our clients maintain the highest safety standards in their workplaces.',
                },
                {
                  question: 'How does your training program benefit clients?',
                  answer: 'Our training program equips clients with the knowledge and tools needed to create and maintain a safe work environment. Benefits include reduced workplace accidents, improved compliance with health and safety regulations, enhanced employee awareness and engagement, and a stronger overall safety culture.',
                },
                {
                  question: 'Who are your typical clients?',
                  answer: 'Our clients come from a wide range of industries, including construction, manufacturing, healthcare, and corporate offices. We cater to businesses of all sizes, from small enterprises to large corporations, providing tailored solutions to meet their unique health and safety needs.',
                },
                {
                  question: 'What sets your company apart from other health and safety consultants?',
                  answer: 'Our company stands out due to our personalized approach, deep industry expertise, and commitment to excellence. We don\'t just offer generic solutions; we work closely with each client to understand their specific challenges and develop customized training and consulting programs that deliver real, measurable results.',
                },
                {
                  question: 'Can you share a success story from a recent client?',
                  answer: 'Absolutely! Recently, we worked with a mid-sized manufacturing company that was struggling with frequent workplace injuries and non-compliance issues. After our comprehensive training and consulting services, they saw a 40% reduction in accidents within six months and achieved full compliance with all relevant health and safety regulations. The improvement not only boosted employee morale but also significantly reduced costs associated with workplace injuries.',
                },
              ].map((faq, index) => (
                <div key={index} className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`} onClick={() => toggleFAQ(index)}>
                  <h3><span className="num">{index + 1}.</span> <span>{faq.question}</span></h3>
                  <div className="faq-content">
                    <p>{faq.answer}</p>
                  </div>
                  <i className={`faq-toggle bi ${activeIndex === index ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
