import axios from 'axios';

const API_URL = 'https://djangomyaip.hoseconsultsugandaltd.com/api/auth/';
const API_URL_FOR_PROFILE = 'https://djangomyaip.hoseconsultsugandaltd.com/api/';

const login = (username, password) => {
    return axios
        .post(API_URL + 'login/', { username, password })
        .then(response => {
            if (response.data.key) {
                localStorage.setItem('user', JSON.stringify({
                    token: response.data.key,
                    username: username,
                }));
            }
            return response.data;
        });
};

const logout = () => {
    return axios.post(API_URL + 'logout/').then(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('user');
        window.location.reload();
        
    });
};

const signup = (username, password, firstName, lastName, email, profileData) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('profile.profile_img', profileData.profile_img);
    formData.append('profile.city', profileData.city);
    formData.append('profile.state', profileData.state);
    formData.append('profile.country', profileData.country);
    formData.append('profile.phone_number', profileData.phone_number);
    formData.append('profile.zip_code', profileData.zip_code);

    return axios.post(API_URL_FOR_PROFILE + 'register/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};


const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

const getCurrentUserName = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? user.username : null;
};

const getProfile = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return axios.get(API_URL_FOR_PROFILE + 'profile/', {
            headers: { Authorization: `Token ${user.token}` }
        }).then(response => response.data);
    } else {
        return Promise.reject('No user is logged in');
    }
};

const updateProfile = (formData) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return axios.put(API_URL_FOR_PROFILE + 'profile/', formData, {
            headers: {
                'Authorization': `Token ${user.token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
    } else {
        return Promise.reject('No user is logged in');
    }
};

const AuthService = {
    login,
    logout,
    signup,
    getCurrentUser,
    getCurrentUserName,
    getProfile,
    updateProfile
};

export default AuthService;
