import React, { useState } from 'react';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import AuthService from '../components/AuthService';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [profileImg, setProfileImg] = useState(null);
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};

        const lettersOnly = /^[A-Za-z]+$/;
        const numbersOnly = /^[0-9]+$/;
        const lettersAnd8Chars = /^[A-Za-z0-9]{6,}$/;

        if (!firstName) newErrors.firstName = 'First name is required';
        else if (!lettersOnly.test(firstName)) newErrors.firstName = 'First name must contain letters only';

        if (!lastName) newErrors.lastName = 'Last name is required';
        else if (!lettersOnly.test(lastName)) newErrors.lastName = 'Last name must contain letters only';

        if (!email) newErrors.email = 'Email is required';

        if (!phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        else if (!numbersOnly.test(phoneNumber)) newErrors.phoneNumber = 'Phone number must contain numbers only';

        if (!country) newErrors.country = 'Country is required';
        else if (!lettersOnly.test(country)) newErrors.country = 'Country must contain letters only';

        if (!city) newErrors.city = 'City is required';
        else if (!lettersOnly.test(city)) newErrors.city = 'City must contain letters only';

        if (!state) newErrors.state = 'State is required';
        else if (!lettersOnly.test(state)) newErrors.state = 'State must contain letters only';

        if (!zipCode) newErrors.zipCode = 'Zip code is required';
        else if (!numbersOnly.test(zipCode)) newErrors.zipCode = 'Zip code must contain numbers only';

        if (!profileImg) newErrors.profileImg = 'Profile image is required';

        if (!username) newErrors.username = 'Username is required';
        else if (!lettersAnd8Chars.test(username)) newErrors.username = 'Username must contain letters only and be at least 6 characters long';

        if (!password) newErrors.password = 'Password is required';
        else if (!lettersAnd8Chars.test(password)) newErrors.password = 'Password must contain letters or numbers and be at least 8 characters long';

        if (!confirmPassword) newErrors.confirmPassword = 'Confirm password is required';
        else if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setMessage('Please fix the errors above');
            return;
        }

        setLoading(true); // Set loading to true when the process starts

        const profileData = { 
            profile_img: profileImg, 
            city, 
            state, 
            country, 
            phone_number: phoneNumber, 
            zip_code: zipCode 
        };

        AuthService.signup(username, password, firstName, lastName, email, profileData).then(
            () => {
                
                setLoading(false); // Set loading to false when the process ends
                alert('Account Created please Login !!')
                navigate('/loginall');
                setMessage('Account created');
                window.location.reload();
            },
            error => {
                setLoading(false); // Set loading to false when the process ends
                if (error.response && error.response.data && error.response.data.errors) {
                    const apiErrors = error.response.data.errors;
                    const newErrors = {};
                    if (apiErrors.username) newErrors.username = apiErrors.username[0];
                    if (apiErrors.email) newErrors.email = apiErrors.email[0];
                    setErrors(newErrors);
                    setMessage('Signup failed. Please fix the errors above.');
                } else {
                    setMessage('Signup failed. Try again.');
                }
            }
        );
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="firstname" className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter First Name"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                isInvalid={!!errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="lastname" className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Last Name"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                isInvalid={!!errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group controlId="email" className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="phoneNumber" className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter phone number with country code"
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                        isInvalid={!!errors.phoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="country" className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter country"
                        value={country}
                        onChange={e => setCountry(e.target.value)}
                        isInvalid={!!errors.country}
                    />
                    <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                </Form.Group>

                <Row>
                    <Col md={6}>
                        <Form.Group controlId="city" className="mb-3">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter city"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                isInvalid={!!errors.city}
                            />
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="state" className="mb-3">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter state"
                                value='Kampala'
                                onChange={e => setState(e.target.value)}
                                
                            />
                            <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group controlId="zip" className="mb-3">
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter zip code"
                                value='256'
                                onChange={e => setZipCode(e.target.value)}
                                
                            />
                            <Form.Control.Feedback type="invalid">{errors.zipCode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group controlId="profileImage" className="mb-3">
                    <Form.Label>Profile Image Optional </Form.Label>
                    <Form.Control
                        type="file"
                        onChange={e => setProfileImg(e.target.files[0])}
                        
                    />
                    <Form.Control.Feedback type="invalid">{errors.profileImg}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="username" className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Choose Username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        isInvalid={!!errors.username}
                    />
                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="password" className="mb-3">
                    <Form.Label>Create Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="confirmPassword" className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        isInvalid={!!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>

                {message && <div className="alert alert-danger" role="alert">{message}</div>}
                <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Register'}
                </Button>
            </Form>
        </>
    );
};

export default Register;
