import React, { useState, useEffect } from 'react';
import { Button, Card, Container, ProgressBar, Row, Col, Modal } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Heroallcourse from '../Herocourse';
import AuthService from '../../components/AuthService';



const Quiz = () => {
  
  const [userData, setUserData] = useState({
        
        first_name: '',
        last_name: '',
       
    });
    const [error, setError] = useState(null);
    

    useEffect(() => {
        AuthService.getProfile()
            .then(data => {
                setUserData({
                    ...data,
                   
                });
            })
            .catch(err => {
                setError('Failed to fetch user data.');
            });
    }, []);
  const questions = [
    {
      question: "1. Q: What is the first step in providing first aid?",
      options: ["a) Assess the scene for safety", "b) Apply direct pressure to the wound", "c) Perform rescue breaths", "d) Elevate the injured limb" ],
      answer: "a) Assess the scene for safety"
    },
    {
      question: "2. Q: What is the recommended compression-to-breath ratio for CPR in adults?",
      options: ["a) 5 compressions to 1 breath",
        "b) 15 compressions to 2 breaths",
        "c) 30 compressions to 2 breaths",
        "d) 30 compressions to 5 breaths"
        ],
      answer: "c) 30 compressions to 2 breaths"
    },
    {
      question: "3. Q: What is the correct hand placement for chest compressions during CPR on an adult?",
      options: ["a) Lower half of the breastbone (sternum)", "b) Middle of the chest, between the nipples", "c) Upper abdomen, just below the ribcage","d) Side of the chest, over the ribs"],
      answer: "b) Middle of the chest, between the nipples"
    },
    
    {
      question: "4. Q: What is the first thing you should do when someone is choking?",
      options: ["a) Perform back blows",
        "b) Begin CPR",
        "c) Perform abdominal thrusts (Heimlich maneuver)",
        "d) Offer them water to drink"
        ],
      answer: "a) Perform back blows"
    
    },
    {
      question: "5. Q: How do you recognize the signs of a heart attack?",
      options: ["a) Chest pain or discomfort, shortness of breath, nausea",
        "b) Dizziness, rapid heartbeat, sweating",
        "c) Pale skin, weakness, confusion",
        "d) Severe headache, slurred speech, numbness in one side of the body"
        ],
      answer: "a) Chest pain or discomfort, shortness of breath, nausea"
    
    },
    {
      question: "6.Q: What is the correct method to control severe bleeding?",
      options: ["a) Apply a tourniquet above the bleeding site",
        "b) Apply pressure directly to the wound with a clean cloth or your hand",
        "c) Elevate the injured limb above the level of the heart",
        "d) Apply ice to the bleeding area"
        ],
      answer: "b) Apply pressure directly to the wound with a clean cloth or your hand"
    
    },
    {
      question: "7. Q: What is the primary purpose of the recovery position?",
      options: ["a) Maintain an open airway",
        "b) Stop bleeding from a wound",
        "c) Prevent further injury to the spine",
        "d) Control severe bleeding"
        ],
      answer: "a) Maintain an open airway"
    
    },
    {
      question: "8. Q: How should you remove a small embedded object, such as a splinter?",
      options: ["a) Use tweezers to pull it out",
        "b) Cut around it with a scalpel",
        "c) Push it further into the skin to avoid infection",
        "d) Use a clean needle to gently lift it out"
        ],
      answer: "d) Use a clean needle to gently lift it out"
    
    },
    {
      question: "9. Q: What should you do if someone is experiencing a seizure?",
      options: ["a) Place a spoon in their mouth to prevent them from biting their tongue",
       " b) Hold them down to prevent movement during the seizure",
        "c) Clear the area around them of any objects that could cause injury",
        "d) Pour water over their face to wake them up"
        ],
      answer: "c) Clear the area around them of any objects that could cause injury"
    
    },
    {
      question: "10. Q: What is the first step in caring for a burn?",
      options: ["a) Apply a cold compress or immerse the burn in cool water",
        "b) Pop any blisters that have formed",
        "c) Apply ointment or butter to the burn",
        "d) Cover the burn loosely with a sterile dressing"
        ],
      answer: "a) Apply a cold compress or immerse the burn in cool water"
    
    },
    {
      question: "11. Q: What is the correct technique for using an automated external defibrillator (AED)?",
      options: ["a) Apply the pads directly on the chest without removing clothing",
        "b) Place one pad on the chest and the other on the back",
        "c) Rub the pads together to warm them before applying",
        "d) Press the pads firmly on the bare chest as indicated by the AED"
        
        ],
      answer: "d) Press the pads firmly on the bare chest as indicated by the AED"
    
    },
    {
      question: "12. Q: What is the recommended treatment for a nosebleed?",
      options: ["a) Tilt the head back to stop the bleeding",
        "b) Pinch the nostrils together and lean forward slightly",
        "c) Blow forcefully to clear the blood from the nose",
        "d) Insert a cotton swab into the nostril to absorb the blood"
        
        ],
      answer: "b) Pinch the nostrils together and lean forward slightly"
    
    },
    {
      question: "13. Q: What is the correct method to remove a bee stinger?",
      options: ["a) Scrape it off with a credit card or your fingernail",
        "b) Squeeze it out with tweezers",
        "c) Apply ice to numb the area, then pull it out with tweezers",
        "d) Leave it in place and seek medical attention"
        
        
        ],
      answer: "a) Scrape it off with a credit card or your fingernail"
    
    },
    {
      question: "14. Q: What should you do if someone is experiencing a severe allergic reaction (anaphylaxis)?",
      options: ["a) Offer them a glass of water to drink",
        "b) Keep them warm and elevate their legs",
        "c) Administer an epinephrine auto-injector if available and call emergency services",
        "d) Apply a heat pack to the affected area"],
      answer: "c) Administer an epinephrine auto-injector if available and call emergency services"
    
    },
    {
      question: "15. Q: What is the correct procedure for removing a tick?",
      options: ["a) Burn it off with a match or lighter",
        "b) Apply petroleum jelly to suffocate the tick, then pull it out",
        "c) Use tweezers to grasp the tick as close to the skin as possible and pull upward with steady pressure",
        "d) Twist the tick counterclockwise to remove it"
        
        ],
      answer: "c) Use tweezers to grasp the tick as close to the skin as possible and pull upward with steady pressure"
    
    },
    {
      question: "16. Q: How do you treat a sprained ankle?",
      options: ["a) Apply heat to the affected area",
        "b) Elevate the injured limb and apply ice wrapped in a cloth",
        "c) Massage the area to reduce swelling",
        "d) Continue to use the injured limb as normal",
        
        ],
      answer: "b) Elevate the injured limb and apply ice wrapped in a cloth"
    
    },
    {
      question: "17. Q: What is the recommended treatment for a dislocated joint?",
      options: ["a) Manipulate the joint to try to put it back into place",
        "b) Apply a hot compress to relax the muscles around the joint",
        "c) Immobilize the joint and seek medical attention",
        "d) Stretch the joint to loosen the surrounding ligaments",
        
        ],
      answer: "c) Immobilize the joint and seek medical attention"
    
    },
    {
      question: "18. Q: What should you do if someone is experiencing a heatstroke?",
      options: ["a) Encourage them to drink cold water as quickly as possible",
        "b) Move them to a cool place, remove excess clothing, and apply cool, wet towels or ice packs",
        "c) Wrap them in blankets to raise their body temperature",
       " d) Massage their limbs to improve circulation",
        ],
      answer: "b) Move them to a cool place, remove excess clothing, and apply cool, wet towels or ice packs"
    
    },
    {
      question: "19. Q: What is the correct technique for administering CPR to an infant?",
      options: ["a) Use only chest compressions without rescue breaths",
        "b) Perform rescue breaths without chest compressions",
        "c) Perform chest compressions with two fingers in the center of the chest",
        "d) Perform chest compressions with the heel of one hand on the center of the chest",
        
        ],
      answer: "c) Perform chest compressions with two fingers in the center of the chest"
    
    },
    {
      question: "20. Q: How do you treat a minor burn that does not involve broken skin?",
      options: ["a) Apply an ice pack directly to the burn",
        "b) Rinse the burn with cold water and cover it with a sterile dressing",
        "c) Apply an adhesive bandage over the burn",
        "d) Apply butter or oil to the burn to soothe the pain"
        
        ],
      answer: "b) Rinse the burn with cold water and cover it with a sterile dressing"
    
    },
    {
      question: "21. Q: What is the recommended treatment for a snakebite?",
      options: ["a) Apply a tourniquet above the snakebite",
        "b) Suck out the venom from the snakebite",
        "c) Immobilize the affected limb and seek medical attention immediately",
        "d) Apply heat to the snakebite area to neutralize the venom"
        
        ],
      answer: "c) Immobilize the affected limb and seek medical attention immediately"
    
    },
    {
      question: "22. Q: How should you care for a minor cut or wound?",
      options: ["a) Rinse the wound with soap and water, apply an antiseptic ointment, and cover it with a sterile dressing",
        "b) Apply alcohol or hydrogen peroxide to clean the wound, then cover it with a bandage",
        "c) Apply pressure to stop the bleeding, then leave the wound uncovered to allow it to air dry",
        "d) Apply a dry cloth to the wound and keep it elevated to reduce swelling"
      
        ],
      answer: "a) Rinse the wound with soap and water, apply an antiseptic ointment, and cover it with a sterile dressing"
    
    },
    {
      question: "23. Q: What is the recommended treatment for a foreign object in the eye?",
      options: ["a) Rub the eye vigorously to dislodge the object",
        "b) Rinse the eye with water or saline solution to flush out the object",
        "c) Attempt to remove the object with a cotton swab or tweezers",
        "d) Cover the eye with a patch and seek medical attention",
        
        ],
      answer: "b) Rinse the eye with water or saline solution to flush out the object"
    
    },
    {
      question: "24. Q: What should you do if someone is experiencing a severe asthma attack?",
      options: ["a) Encourage them to take deep breaths and use a paper bag to regulate their breathing",
        "b) Administer their prescribed inhaler as directed and seek medical help if the symptoms worsen",
        "c) Apply pressure to their chest to relieve tightness and promote airway opening",
        "d) Offer them a glass of warm water to ease their breathing"
        
        ],
      answer: "b) Administer their prescribed inhaler as directed and seek medical help if the symptoms worsen"
    
    },
    {
      question: "25. Q: How should you care for a broken bone or fracture before medical help arrives?",
      options: ["a) Attempt to realign the bone or fracture",
        "b) Apply a cold compress and immobilize the injured limb with a splint or makeshift materials",
        "c) Massage the injured area to increase blood circulation",
        "d) Encourage the individual to walk or move the injured limb",
      
        ],
      answer: "b) Apply a cold compress and immobilize the injured limb with a splint or makeshift materials"
    
    },
    {
      question: "26. Q: What should you do if someone is having a diabetic emergency and is conscious?",
      options: ["a) Offer them sugary food or drink to raise their blood sugar levels",
       "b) Have them lie down and elevate their legs",
        "c) Administer insulin if available, even if you are unsure of the dosage",
        "d) Help them find a comfortable position and monitor their condition closely"
      
        ],
      answer: "a) Offer them sugary food or drink to raise their blood sugar levels"
    
    },
    {
      question: "27. Q: How should you care for a person with a suspected neck or spinal injury?",
      options: ["a) Move them to a more comfortable position to alleviate pain",
        "b) Keep their head and neck immobilized and call for medical help immediately",
        "c) Gently massage their neck and shoulders to relieve tension",
        "d) Apply heat to the affected area to reduce muscle spasms"
        
        ],
      answer: "b) Keep their head and neck immobilized and call for medical help immediately"
    
    },
    {
      question: "28. Q: What is the recommended treatment for a jellyfish sting?",
      options: ["a) Apply vinegar or a baking soda paste to the sting area",
        "b) Rinse the sting area with fresh water and apply heat",
        "c) Rub the sting area with sand or a towel to remove the tentacles",
        "d) Apply ice directly to the sting area to numb the pain"
        
        ],
      answer: "a) Apply vinegar or a baking soda paste to the sting area"
    
    },
    {
      question: "29. Q: What should you do if someone is experiencing a severe allergic reaction to an insect bite or ",
      options: ["a) Offer them antihistamine medication to reduce the reaction",
       "b) Apply a topical steroid cream to the affected area",
        "c) Administer an epinephrine auto-injector if available and seek immediate medical attention",
        "d) Apply a hot compress to the affected area to reduce swelling",
        
        ],
      answer: "c) Administer an epinephrine auto-injector if available and seek immediate medical attention"
    
    },
    {
      question: "30. Q: What is the correct procedure for helping someone who is having a seizure?",
      options: ["a) Hold them down to prevent them from hurting themselves",
        "b) Place a soft object in their mouth to prevent them from biting their tongue",
        "c) Move objects away from them to prevent injury and cushion their head",
        "d) Pour water over their face to try to wake them up"
        
        ],
      answer: "c) Move objects away from them to prevent injury and cushion their head"
    
    }
    
  ];

  const shuffleOptions = (options) => {
    for (let i = options.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [options[i], options[j]] = [options[j], options[i]];
    }
    return options;
  };

  const shuffledQuestions = questions.map(q => ({
    ...q,
    options: shuffleOptions([...q.options])
  }));

  const totalQuestions = shuffledQuestions.length;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [showCongrats, setShowCongrats] = useState(false);
  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);

  useEffect(() => {
    if (isQuizCompleted && progress === 100) {
      setShowCongrats(true);
      setTimeout(() => setShowCongrats(false), 5000);
    }
  }, [isQuizCompleted, progress]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    if (selectedOption === shuffledQuestions[currentQuestionIndex].answer) {
      setProgress((prevProgress) => Math.min(prevProgress + (100 / totalQuestions), 100));
      setCorrectAnswers((prevCorrectAnswers) => prevCorrectAnswers + 1);
    }
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null);
    } else {
      setIsQuizCompleted(true);
      if (correctAnswers === totalQuestions - 1 && selectedOption === shuffledQuestions[currentQuestionIndex].answer) {
        setCorrectAnswers((prevCorrectAnswers) => prevCorrectAnswers );
      }
      if (correctAnswers + 9/** adjust 3 = 30 it adds 30 on your score */ + (selectedOption === shuffledQuestions[currentQuestionIndex].answer ? 1 : 0) < totalQuestions) {
        setIsModalOpen(true);
      }
    }
  };

  const handlePrintCertificate = () => {
    window.print();
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setProgress(0);
    setSelectedOption(null);
    setIsQuizCompleted(false);
    setIsModalOpen(false);
    setCorrectAnswers(0);
  };

  const score = Math.round((correctAnswers / totalQuestions) * 100);

  const generateBubbles = () => {
    let bubbles = [];
    for (let i = 0; i < 50; i++) {
      const size = Math.random() * 40 + 10;
      const left = Math.random() * 100;
      const delay = Math.random() * 5;
      const bubbleStyle = {
        width: `${size}px`,
        height: `${size}px`,
        left: `${left}%`,
        animationDelay: `${delay}s`,
      };
      bubbles.push(<div key={i} className="bubble" style={bubbleStyle}></div>);
    }
    return bubbles;
  };

  return (
    <>
      <Heroallcourse />
      <Container className="mt-4">
        <Modal show={isModalOpen} onHide={resetQuiz}>
          <Modal.Header closeButton>
            <Modal.Title><FaExclamationCircle color="red" /> Quiz Incomplete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You have got {score}%.<br /> You have to get 70% and above to generate the certificate for you. Please repeat the quiz now.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={resetQuiz}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={isCertificateModalOpen} onHide={() => setIsCertificateModalOpen(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Certificate of Completion</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="certificate">
      <div className="certificate-border">
        <img src={`${process.env.PUBLIC_URL}/img/logo.jpg`} alt="Logo" className="certificate-logo" />
        <h1>Certificate of Completion</h1>
        <p style={{color:"white"}} className='certify'>THIS IS TO CERTIFY THAT</p>
        <h2 className='username'>{userData.first_name +" "+  userData.last_name}</h2>
        <p>has successfully completed the (First Aid Course) with a score of</p>
        <h2>{score}%</h2>
        <p>on</p>
        <h3>{new Date().toLocaleDateString()}</h3>
        <p>This is awarded in recognition of the dedication and hard work demonstrated by completing the First Aid Course.</p>
        <div className="certificate-footer">
          <p>Certificate Number: 1053672345</p>
          <div className="signature-section">
            <p className="signature">Signature</p>
            <img src={`${process.env.PUBLIC_URL}/img/blog/sing.jpg`} alt="Seal" className="certificate-seal" /> {/* Replace with the path to your seal */}
            <img style={{width:"70px"}} src={`${process.env.PUBLIC_URL}/img/blog/red.png`} alt="Stamp" className="certificate-stamp" /> {/* Replace with the path to your stamp */}
          </div>
        </div>
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={handlePrintCertificate}>
      Print Certificate
    </Button>
  </Modal.Footer>
</Modal>


        {!isQuizCompleted ? (
          <>
            <Row>
              <Col md={8} className="mx-auto">
                <Card>
                  <Card.Body>
                    <Card.Title>Quiz  Pass Mark Is 70%</Card.Title>
                    <Card.Text>{shuffledQuestions[currentQuestionIndex].question}</Card.Text>
                    {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                      <Button
                        key={index}
                        variant={selectedOption === option ? 'primary' : 'outline-primary'}
                        className="d-block w-100 mb-2"
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option}
                      </Button>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="text-center mt-4">
              <Button onClick={handleNextQuestion} disabled={!selectedOption}>
                {currentQuestionIndex < totalQuestions - 1 ? 'Next Question' : 'Finish Quiz'}
              </Button>
            </div>
          </>
        ) : (
          <div className="text-center">
            <h3>Quiz Completed!</h3>
            <Button onClick={() => setIsCertificateModalOpen(true)} disabled={correctAnswers + 9/**the button to be active after geting 70 and i add 3 = 30 that make it 100 */ < totalQuestions}>
              Generate Certificate 
            </Button>
          </div>
        )}
        <div className="text-center mt-4" style={{ marginBottom: '20px' }}>
          <ProgressBar
            now={progress}
            label={`${Math.round(progress)}%`}
            variant={progress === 100 ? 'success' : 'primary'}
          />
        </div>
      </Container>
      {showCongrats && (
        <div className="bubbles">
          <h1 style={{ color: 'green', textAlign: 'center' }}>Congratulations!</h1>
          {generateBubbles()}
        </div>
      )}
    </>
  );
};

export default Quiz;
