import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import Heroall from './heroall';
import CallToAction from './callto';
import axios from 'axios';

const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('Loading...');
    console.log('Form data:', formData); // Debugging: Log form data
    try {
      const response = await axios.post('https://djangomyaip.hoseconsultsugandaltd.com/api/contact/', formData);
      console.log('Response:', response); // Debugging: Log response
      setFormStatus('Your message has been sent.');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error:', error); // Debugging: Log error
      setFormStatus('Error sending message');
    }
  };

  return (
    <>
      <Heroall />
      <CallToAction />
      <section id="contact" className="contact section">
        <div className="container section-title" data-aos="fade-up">
          <p>Human resources in Uganda and East Africa</p>
        </div>
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row gx-lg-0 gy-4">
            <div className="col-lg-4">
              <div className="info-container d-flex flex-column align-items-center justify-content-center">
                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h3>Address</h3>
                    <p>P.O BOX 7111, KAMPALA (U)/Jinja-Rubaga</p>
                  </div>
                </div>
                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                  <i className="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>Call Us</h3>
                    <p>+256 782 8562 77</p>
                  </div>
                </div>
                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>Email Us</h3>
                    <p>info@hoseconsultsugandltd.com</p>
                  </div>
                </div>
                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
                  <i className="bi bi-clock flex-shrink-0"></i>
                  <div>
                    <h3>Open Hours:</h3>
                    <p>Mon-Sat: 8:00AM - 6:00PM</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="php-email-form" data-aos="fade" data-aos-delay="100" onSubmit={handleSubmit}>
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                      onChange={handleChange}
                      value={formData.name}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                      onChange={handleChange}
                      value={formData.email}
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                      onChange={handleChange}
                      value={formData.subject}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="8"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                      value={formData.message}
                    ></textarea>
                  </div>
                  <div className="col-md-12 text-center">
                    {formStatus === 'Loading...' && <div className="loading">Loading...</div>}
                    {formStatus && formStatus !== 'Loading...' && (
                      <div className={formStatus === 'Your message has been sent.' ? 'sent-message' : 'error-message'}>
                        {formStatus}
                      </div>
                    )}
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
