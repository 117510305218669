import {React,useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './webcomponents/nave';
import '../src/App.css'
import Footer from './webcomponents/footer';
import Hero from './webcomponents/Hero';
import About from './webcomponents/About';

import Services from './webcomponents/services';

import Team from './webcomponents/team';

import Blog from './webcomponents/blog';
import Contact from './webcomponents/contact';
import YourComponent from './webcomponents/your';
import Courses from './webcomponents/couses.jsx';
import CourseDetails from './webcomponents/courseDetails.jsx';
import Checkout from './webcomponents/Checkout.jsx';
import CourseProgress from './webcomponents/courses/mycouse.jsx';
import Quiz from './webcomponents/courses/quiz.jsx';
import Login from './webcomponents/Loginfull.jsx';
import AuthService from './components/AuthService.js';
import UserProfileForm from './webcomponents/UserProfileForm.jsx';


import Underconstraction from './webcomponents/underconstraction.jsx';
import Activatecourse from './webcomponents/activatecourse.jsx';
import Notpaid from './webcomponents/notpaid.jsx';


import PasswordResetRequestForm from './webcomponents/passwordreq.jsx';
import PasswordReset from './webcomponents/passwordcom.jsx';

const currentUser = AuthService.getCurrentUser();


const App = () => {

    const [userData, setUserData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        profile: {
            profile_img: null,
            city: '',
            state: '',
            country: '',
            phone_number: '',
            zip_code: '',
            active:''
        }
    });
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        AuthService.getProfile()
            .then(data => {
                setUserData({
                    ...data,
                    profile: data.profile || {
                        profile_img: null,
                        city: '',
                        state: '',
                        country: '',
                        phone_number: '',
                        zip_code: '',
                        active:''
                    }
                });
            })
            .catch(err => {
                setErrors(prevState => ({
                    ...prevState,
                    general: 'Failed to fetch user data.'
                }));
            });
    }, []);

   const paid = userData.profile.active


    return (
        <Router>
          
            <Header />
            
            
            <Routes>
               {/**  <Route path="/" element={<LoginComponent />} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/signup" element={<SignupComponent />} />
                <Route path="/todos" element={<TodoComponent />} />*/}
                
                
                <Route path="/" element={<Hero/>} />
                <Route path="/couses" element={<Courses/>} />
                <Route path="/loginall" element={<Login/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/services" element={<Services/>} />
                <Route path="/team" element={<Team/>} />
                <Route path="/blog" element={<Blog/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/activatec" element={currentUser?<Activatecourse/>:<Login/>} />
                <Route path="/profile" element={currentUser? <UserProfileForm/>:<Login/>} />
                {/**<Route path="/profile1" element={<Lessons/>} />**/}
                <Route path="/checkout" element={currentUser?<Checkout/>:<Login/>} />
                <Route path="/mycourse" element={paid ?<CourseProgress/>:<Notpaid/>} />
                <Route path="/quiz" element={paid?<Quiz/>:<Login/>} />
                <Route path="/course-details/2" element={<CourseDetails/>} />
                <Route path="/course-details/1" element={<Underconstraction/>} />
                <Route path="/course-details/3" element={<Underconstraction/>} />

                <Route path="/reset-password" element={<PasswordResetRequestForm/>} />
                <Route path="/reset-password-confirm/:token" element={<PasswordReset/>} />



            </Routes>
             
            <Footer/>
            <YourComponent/>
        </Router>
    );
};

export default App;
