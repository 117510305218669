import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import IconBox from './Icon';
import Client from './client';
import StatsSection from './stat';
import CallToAction from './callto';
import Service from './service';
import Testimonials from './Testimonials';
import Portfolio from './portfo';
import Pricing from './pricing';
import FAQ from './faq';
import AuthService from '../components/AuthService';
import Courses from './couses';

import 'aos/dist/aos.css';
import AOS from 'aos';


const currentUser = AuthService.getCurrentUser();
const currentUserName = AuthService.getCurrentUserName();
const servicesData = {
  "HR Consultancy Service": `
    Hose Consults Uganda Ltd offers other services below.
    - HR recruitment services/Consultancy.
    - Mentoring, coaching and psychosocial support & Counselling.
    - Performance evaluation and Employee engagement
    - Head hunting and contract design 
    - HR manual development
    - Public and motivational speaking.
    - Auditing service 
    - Entrepreneurship skills and training.
    - Leadership and capacity building training/ supervisory and management trainings.
    - Business Continuity plan formulation, we draft good plan for our clients for sustainability and continuity respective of harsh conditions.
  `,
  "Environmental Consultancy Services": `
    1. Environmental Social Impact Assessment (ESIA)
    2. Develop and formulate Environmental policy.
    3. Development of Environmental Manuals.
    4. Conduct Environmental Audits and assessment.
    5. Water Quality Analysis and Wastewater quality analysis.
    6. Air Quality and emission monitoring at workplace.
    7. Noise assessment and surveillance at workplaces.
    8. Basics of environmental protocols and regulations training
    9. Chemical safety and environmental pollution management training course.
    10. Occupational hygienist consultancy services.
    11. Solid waste management training Course.
    12. Bio medical waste management training course.
  `,
  "OSH Consultancy Services": `
    1. OSH Audit.
    2. Fire Safety Prevention and Fighting Training
    3. First Aid Training Course at workplace.
    4. Forklift Operator Training Certification Course.
    5. Mental Health First Aid Course (MHFA) at workplace.
    6. Boiler operators’ technical Training and certification.
    7. Emergency preparedness and response Training Course/Business Continuity Planning.
    8. Compressed Gas Safety Management Training Course.
    9. Scaffolding and ladder safety Training course.
    10. Construction Safety and Management Training course.
    11. Management of Contractors & Visitors Training Course.
    12. Formation of Joint Health Safety Committees at Workplace Trainings.
    13. Understanding the Basics of legal laws, regulations, acts and Enforcement at workplace.
    14. Defensive Driving Training/Fleet Safety Management Training.
    15. Incident/Accident Investigation and Management Training Course.
    16. Hazardous Energy (LOTO) Procedures Training Course.
    17. Hearing Conservation and Respiratory Training Course.
    18. Manual Material Safe Handling, Lifting, Pulling & Pushing Training
    19. PPE (Personnel Protective Equipment) use, storage, and Maintenance Training Course.
    20. Laboratory Professional Ethics and Safety Training Course.
    21. Work at Height (Fall Protection/Construction Safety) Training Course.
    22. Confined Space Entry (CSE), Attendants, Rescuers and Supervisors Training 
    23. Permit to Work (PTW) systems Training.
    24. Electrical Safety and IP Rating Training
    25. Machine Safety Guarding Training Course
    26. Flammable Liquid Management Training Course
    27. Drafting HSE & Quality Policy in line with ISO 9001:2015, 14001:2015, ISO
    45001
    28. Basic Safety Induction for Supervisors, managers and employees at workplace.
    29. HIV/Aids Training and Counseling services.
    30. Ergonomics and Office Based Safety Training.
  `,
  "Security Consultancy Services": `
  1. Security risk assessments and audits.
  2. Development of security policies and procedures.
  3. Training on security awareness and protocols.
  4. Emergency response planning and management.
  5. Physical security design and implementation.
  6. Security systems integration (CCTV, access control, etc.).
  7. Threat and vulnerability assessments.
  8. Crisis management and business continuity planning.
  9. Executive protection and personal security.
  10. Cybersecurity consultancy and risk management.
  11. Development of incident response plans.
  12. Security training for staff and management.
  13. Fraud and theft prevention strategies.
  14. Security compliance audits and assessments.
  15. Investigative services and intelligence gathering.
  16. Development of travel security plans.
  17. Event security planning and management.
  18. Maritime and aviation security consultancy.
  19. Security project management.
  20. Security technology advisory services.
  `,
  "Quality Consultancy Services": `
    1. Development of HSEQ management system.
    2. Food safety and HACCP Training Course.
    3. Development of Quality Manual.
    4. Development of quality policy.
    5. Prepare organizations to achieve Q-mark.
    6. Conducting stage1 and stage 2 Audits for certification purposes.
    7. Develop purchasing manuals.
    8. Developing Standard operating procedures (SOPs) for any process.
    9. Supplier Quality Assurance (SQA) evaluations.
    10. Site Inspection and gap analysis.
  `
};

const Hero = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const handleIconClick = (title) => {
    setModalTitle(title);
    setModalContent(servicesData[title]);
    setShowModal(true);
  };
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    
    {! currentUser?
    
    <>
      <section id="hero" className="hero section">
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-5 justify-content-between">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h2>Welcome To <span className="accent">Hose Consults Ugand ltd</span></h2>
              <p>A leading consultant in occupational safety, health, environment and quality trainings, risk assessment, inspections, entrepreneur, Incident...</p>
              <div className="d-flex">
                <a href="#about" className="btn-get-started">Read More</a>
                <a href={`${process.env.PUBLIC_URL}/img/videos/herovideo.mp4`} className="glightbox btn-watch-video d-flex align-items-center">
                  <i className="bi bi-play-circle"></i><span>Watch Video</span>
                </a>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2">
              <img src={`${process.env.PUBLIC_URL}/img/Onboarding-amico.svg`} className="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div className="icon-boxes position-relative" data-aos="fade-up" data-aos-delay="200">
          <div className="container position-relative">
            <div className="row gy-5 mt-5">
              {Object.keys(servicesData).map((title, index) => (
                <div className="col-lg-4 col-md-6 iconboxes" key={index}>
                  <div className="icon-box" onClick={() => handleIconClick(title)}>
                    <div className="icon"><i className={`bi ${getIconClass(title)}`}></i></div>
                    <h4 className="title"><a href="#">{title}</a></h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{modalContent}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
    <Service/>
    <Client/>
    <StatsSection/>
    <CallToAction/>
    <Testimonials/>
    <Portfolio/>
    <Pricing/>
    <FAQ/>
    </>:<>
    <Courses/>
    </>}</>
  );
};

// Function to get the appropriate icon class based on the service title
const getIconClass = (title) => {
  switch (title) {
    case "HR Consultancy Service":
      return "bi-person-fill-gear";
    case "Environmental Consultancy Services":
      return "bi-tree-fill";
    case "OSH Consultancy Services":
      return "bi-heart-pulse-fill";
    case "Security Consultancy Services":
      return "bi-shield-lock-fill";
    case "Quality Consultancy Services":
      return "bi-award-fill";
    default:
      return "bi-info-circle-fill";
  }
};

export default Hero;
