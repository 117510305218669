import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css'; // Ensure you have AOS library installed and imported
import AOS from 'aos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faEye, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';

const Service = () => {
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const handleClose = () => setShow(false);
  const handleShow = (title, body) => {
    setModalContent({ title, body });
    setShow(true);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div className="row g-5 services-inner">
            <div
              className="col-md-6 col-lg-4 wow fadeIn"
              data-wow-delay=".3s"
              style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeIn' }}
            >
              <div className="services-item1 bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <FontAwesomeIcon icon={faMedal} className="fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">OUR CORE VALUES</h4>
                    <p className="mb-4">
                      We proud ourselves in the (RIQIC) as our core values, our driving forces to exist in the competition 
                      and market are none other than our best services and ...
                    </p>
                    <Button
                      variant="success"
                      className="px-5 py-3 rounded-pill"
                      onClick={() =>
                        handleShow('OUR CORE VALUES', `We proud ourselves in the (RIQIC) as our core values, our driving forces to exist in the competition and market are none other than our best services and trainings that are embedded into our five core values of Hose Consults Uganda Ltd. <br><br/>
Responsibility:<br><br/>
Hose Consults Uganda Ltd is responsible for setting up good relationships with our clients and Students. We ensure that the best services and training materials are offered to our clients and students so that they keep in a competitive world in terms of compliance to legal Acts, Regulations, Laws, and ISO standards.
Our employees are very responsible for every action they take, and human resource capital is vital for sustainable development. <br><br/>

Integrity:<br><br/>
We are honest and truthful in all our actions at Hose Consults Uganda Limited. All our services are in line with both local legal and international requirements. We believe in always doing the right thing and this has helped us to maintain and acquire more clients/students for our services and Trainings. <br><br/>

Quality:<br><br/>
We offer the best Trainings & coaching, inspections and Auditing services in occupational Health, Safety and Environment, and Quality. We prepare an organization to meet all requirements for International Standardization. 
ISO 9001:2015, ISO 14001:2015, OHSAS 18001:2007 (ISO 45001), ISO 50001 and ISO 31000: 2009. And for students taking up courses, they are equipped with knowledge and skills in inspections/Auditing and applications of the ISO Standards. 
The Human resource specialist team delivers the best mentoring and coaching and offers different psychosocial support and counseling among other HR aspects.<br><br/>

Innovation:<br><br/>
The current world is changing day and night in terms of technology and approach to occupational Health, Safety and Environment and Quality. We keep ourselves with up-to-date information so as to deliver the best materials to our students and our clients/customers. The modifications in machinery and changes in approach to risk assessment methodologies have created a new trend in a competitive world. 
The Human resource pillar of Hose Consults Uganda ltd is equipment with the current HR tools and application with a strong and experienced team in Human resource management services.<br><br/>
          
Customer/Clients:<br><br/>
Our clients/customers are the major reasons we exist and therefore we aim at giving them the best services that are internationally/globally applicable in terms of knowledge and skills. Customer/ Client satisfaction is a key at HOSE CONSULTS Uganda Ltd.  Our students are who enroll for our courses are equipped with knowledge and skills in real life situations in regards to Occupational Health, Safety and Environment, and Quality.   
`)
                      }
                    >
                      Read More
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeIn"
              data-wow-delay=".5s"
              style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeIn' }}
            >
              <div className="services-item1 bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <FontAwesomeIcon icon={faEye} className="fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">VISION</h4>
                    <p className="mb-4">
                      We shall lead in consultancy and Trainings on Occupational Health, Safety and Environment, Security, 
                      Quality and Human resource services in Uganda and East Africa...
                    </p>
                    <Button
                      variant="success"
                      className="px-5 py-3 rounded-pill"
                      onClick={() =>
                        handleShow('VISION', `We shall lead in consultancy and trainings on Occupational Health, Safety, and Environment, Security, Quality, and Human Resource services in Uganda and East Africa. Our vision is to set the benchmark for excellence in these critical areas, ensuring that our clients are not only compliant with national and international standards but also are empowered with the knowledge and tools to proactively manage and enhance their operational health and safety.<br></br>

By fostering a culture of continuous improvement and innovation, we aspire to be the first choice for organizations seeking to elevate their practices in occupational safety, environmental stewardship, and workforce management. We are committed to providing solutions that are not only effective and sustainable but also tailored to the unique needs of our clients.<br></br>

Our goal is to build long-term partnerships that drive meaningful change and contribute to the economic and social development of the region. Through our dedicated team of experts, cutting-edge training programs, and a deep understanding of the local and regional challenges, we will influence policy, shape industry standards, and create safer, more efficient workplaces. Ultimately, we aim to be recognized as the premier consultancy and training organization across East Africa, guiding our clients towards a safer and more prosperous future.`)
                      }
                    >
                      Read More
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeIn"
              data-wow-delay=".7s"
              style={{ visibility: 'visible', animationDelay: '0.7s', animationName: 'fadeIn' }}
            >
              <div className="services-item1 bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">MISSION</h4>
                    <p className="mb-4">
                      To be the leading Training and consultancy organization in the field of Occupational Health, Safety, & Environment, 
                      Risk assessment, Inspections, and Auditing services and Human ...
                    </p>
                    <Button
                      variant="success"
                      className="px-5 py-3 rounded-pill"
                      onClick={() =>
                        handleShow('MISSION', `To be the leading Training and consultancy organization in the 
                          field of Occupational Health, 
Safety, & Environment, Risk assessment, Inspections, and Auditing services and Human resources services
 in Uganda and East Africa. We shall provide the latest information in Occupational Health, Safety, 
 Environment and Quality training to our students and organizations. The Human resources section shall 
 deliver the best HR modules and policy implementation, coaching, psychosocial support & counselling, 
 performance evaluation &Training.
`)
                      }
                    >
                      Read More
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{ __html: modalContent.body }} />

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Service;
