import React from 'react';
import NavMenu from './NavMenu';
import AuthService from '../components/AuthService';
import  { useEffect, useState } from 'react';


const currentUser = AuthService.getCurrentUser();
const currentUserName = AuthService.getCurrentUserName();



const Header = () => {
  

  const [userData, setUserData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    profile: {
        profile_img: null,
        city: '',
        state: '',
        country: '',
        phone_number: '',
        zip_code: ''
    }
});
const [ , setError] = useState(null);


useEffect(() => {
    AuthService.getProfile()
        .then(data => {
            setUserData({
                ...data,
                profile: data.profile || {
                    profile_img: null,
                    city: '',
                    state: '',
                    country: '',
                    phone_number: '',
                    zip_code: ''
                }
            });
        })
        .catch(error => {
            setError('Failed to fetch user data.');
            
        });
  }, []);
  
  return (
    <header id="header" className="header fixed-top">
      <div className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            {!currentUser?<i className="bi bi-envelope d-flex align-items-center">
              <a href="mailto:contact@hoseconsultsugandltd.com">contact@hoseconsultsugandltd.com</a>
            </i>:''}
            <i className="bi bi-phone d-flex align-items-center ms-4"><span>+256 782 8562 77</span></i>
            {currentUser? <i className="bi bi-person-fill-check d-flex align-items-center ms-4"><span>HOSE CONSULTS UGANDA LTD</span></i>:""}
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
            <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
            <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
            <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
          </div>
        </div>
      </div>
      <div className={currentUser?`branding d-flex align-items-center userbg`:`branding d-flex align-items-center` }>
        <div className="container position-relative d-flex align-items-center justify-content-between">
         { !currentUser ? <a href="/" className="logo d-flex align-items-center">
            <img  src={`${process.env.PUBLIC_URL}/img/logo.jpg`} alt="" />
            <h1 className="sitename">Hoseconsult</h1>
          </a>:
          
          <a href="/" className="logo d-flex align-items-center">
          <img className="img-fluid post-author-img flex-shrink-0 imguser"  src={userData.profile?.profile_img} alt="" />
          <h1 className="sitename">{currentUserName}</h1>
        </a>}
          
          <NavMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
